import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

// import i18n (needs to be bundled ;))
import './localization/i18n';
import './index.scss';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import './services/mockApi';
import AppConfigProvider from './components/AppConfigProvider';

// kick off the polyfill!
smoothscroll.polyfill();

// @ts-ignore
const initialData = window.initialData || {};

ReactDOM.render(
  <React.StrictMode>
    <AppConfigProvider initialData={initialData}>
      <Provider store={store}>
        <App />
      </Provider>
    </AppConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
