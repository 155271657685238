import React, { useCallback, useState } from 'react';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Drawer, IconButton } from '@material-ui/core';

import './appBar.scss';
import { Logo, LogoEn } from '../logo';
import { CloseIcon, MenuIcon, SearchIcon } from '../../Icons';
import { Children, ClassName } from '../../types';
import {
  ARCHIVE_PAGE,
  GALLERY_PAGE,
  MAIN_PAGE,
  NEWS_PAGE,
  PROGRAM_PAGE,
  SIGNUP_CONDITIONS_PAGE,
  SIGNUP_PAGE,
  SPEAKERS_PAGE
} from '../../../routes';
import { ResourcesBlock, SocialNetworks } from '../footer/Footer';
import { useToggle, useUpdateEffect } from '../../../hooks/react';
import SearchDrawer from '../search/Search';
import Button, { ButtonProps } from '../../buttons/Buttons';
import { AppConfig, PageAliases, useAppConfig } from '../../AppConfigProvider';

interface PageLink {
  className?: string;
  alias: PageAliases;
  to: string;
  title: any;
}

const links = Object.fromEntries([
  {
    className: 'secondary',
    alias: 'signup',
    to: SIGNUP_PAGE,
    title: 'appbar.signup',
  },
  {
    alias: 'signup_conditions',
    to: SIGNUP_CONDITIONS_PAGE,
    title: 'appbar.signup_conditions',
  },
  {
    alias: 'program',
    to: PROGRAM_PAGE,
    title: 'appbar.program',
  },
  {
    alias: 'speakers',
    to: SPEAKERS_PAGE,
    title: 'appbar.speakers',
  },
  {
    alias: 'news',
    to: NEWS_PAGE,
    title: 'appbar.news',
  },
  {
    alias: 'gallery',
    to: GALLERY_PAGE,
    title: 'appbar.gallery',
  },
  {
    alias: 'archive',
    to: ARCHIVE_PAGE,
    title: 'appbar.archive',
  },
].map(i => [i.alias, i])) as { [key in PageAliases]: PageLink };

function getPageLinks(pages: AppConfig['pages']) {
  return Array.from(pages.entries())
    .filter(([, pageEnabled]) => pageEnabled)
    .map(([alias]) => links[alias])
    .filter(i => i != null);
}

export function useAppPageLinks(): PageLink[] {
  const { pages } = useAppConfig();
  const [appLinks, setAppLinks] = useState<PageLink[]>(() => getPageLinks(pages));
  useUpdateEffect(() => {
    setAppLinks(getPageLinks(pages));
  }, [pages]);
  return appLinks;
}

export default function AppBar() {
  const { t, i18n: { language } } = useTranslation();
  const links = useAppPageLinks();
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isSearchOpen, toggleSearch] = useToggle(false);
  const logo = language === 'en' ? <LogoEn /> : <Logo />
  return (
    <>
      <header className={classnames('AppBar')}>
        <RouterLink to={MAIN_PAGE} className={'AppBar__logo'} aria-label="logo">{logo}</RouterLink>
        <div className={classnames('AppBar__container', 'large')}>
          <nav>
            {links.map(i => <NavLink className={i.className} key={i.to} to={i.to}>{t(i.title)}</NavLink>)}
          </nav>
          <LanguageSwitcher size="small" />
          <SearchButton onClick={toggleSearch} />
        </div>
        <div className={classnames('AppBar__container', 'mobile')}>
          <IconButton aria-label="поиск" className={'IconButton'} color="primary" onClick={toggleSearch}>
            <SearchIcon />
          </IconButton>
          <IconButton aria-label="меню" className={'IconButton'} color="primary" onClick={toggleMenu}>
            {!isMenuOpen ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
        </div>
      </header>
      <NavigationDrawer open={isMenuOpen} onClose={toggleMenu} />
      <SearchDrawer open={isSearchOpen} onClose={toggleSearch} />
    </>
  );
}

function NavigationDrawer({ open, onClose }: { open: boolean, onClose?: () => void }) {
  const { t } = useTranslation();
  const links = useAppPageLinks();
  const handleClick = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);
  return (
    <Drawer
      className={'Drawer'} classes={{ paper: 'paper' }}
      anchor='right' open={open}
      onClose={onClose}
    >
      <IconButton aria-label="закрыть" className={'IconButton'} color="secondary" onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Box flex={1} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
        <nav>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {links.map(i => <NavLink key={i.to} to={i.to} onClick={handleClick}>{t(i.title)}</NavLink>)}
          </Box>
          <LanguageSwitcher onClick={handleClick} />
        </nav>
        <Box marginTop="80px">
          <ResourcesBlock />
          <SocialNetworks className={'Drawer__socNetworks'} />
        </Box>
      </Box>
    </Drawer>
  );
}

function NavLink({ className, to, children, onClick }: ClassName & { to: string, onClick?: () => void } & Children) {
  return (
    <RouterNavLink to={to} className={classnames('NavLink', className)} onClick={onClick}>
      {children}
    </RouterNavLink>
  );
}

const languageItems = {
  ru: { code: 'ru', title: 'русский' },
  en: { code: 'en', title: 'english' },
} as const;

export function useSwitchLanguage({ callback }: { callback?: () => void } = {}) {
  const { language: { switchingEnabled } } = useAppConfig();
  const { i18n, i18n: { language } } = useTranslation();
  const languageToSwitch = language === 'ru' ? languageItems.en : languageItems.ru;
  const switchLanguage = useCallback(() => {
    i18n.changeLanguage(languageToSwitch.code, callback);
  }, [i18n, languageToSwitch.code, callback]);
  return { language, languageToSwitch, switchLanguage, show: switchingEnabled };
}

function LanguageSwitcher({ size, onClick }: { size?: 'small', onClick?: () => void }) {
  const { switchLanguage, languageToSwitch, show } = useSwitchLanguage({ callback: onClick });
  if (!show) return null;
  const label = size === 'small' ? languageToSwitch.code.toUpperCase() : languageToSwitch.title;
  return (
    <div role="button" aria-label="язык" tabIndex={0} className={'LanguageSwitcher'}
         onClick={switchLanguage}>{label}</div>
  );
}

function SearchButton({ onClick }: { onClick?: () => void }) {
  return (
    <RectButton aria-label="поиск" variant="contained" color="primary" onClick={onClick}>
      <SearchIcon />
    </RectButton>
  );
}

export function RectButton(props: ButtonProps) {
  return (<Button className={'RectButton'} variant="contained" color="primary" {...props} />);
}
