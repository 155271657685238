import { useEffect, useRef, useState } from 'react';

interface DebounceValueOptions {
  timeoutMs?: number;
}

export function useDebounceValue<T = any>(value: T, options?: DebounceValueOptions): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const { timeoutMs = 300 } = options || {};
  useEffect(
    () => {
      if (value === debouncedValue) return;
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, timeoutMs);
      return () => {
        clearTimeout(handler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, timeoutMs]
  );
  return debouncedValue;
}

interface useDebounceCallbackOptions<T> extends DebounceValueOptions {
  callback: (value: T) => void;
  callOnInit?: boolean;
}

export function useDebounceCallback<T = any>(value: T, options: useDebounceCallbackOptions<T>) {
  const { callback, callOnInit = false, timeoutMs } = options;
  const inited = useRef(false);
  const callbackRef = useRef(callback);
  const debouncedValue = useDebounceValue<T>(value, { timeoutMs });
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect(() => {
    if (!callOnInit && !inited.current) {
      inited.current = true;
      return;
    }
    callbackRef.current(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
}

export function useDebounceStateCallback<T = any>(options: { initialValue: T } & useDebounceCallbackOptions<T>) {
  const { initialValue } = options;
  const state = useState<T>(initialValue);
  const [value, setValue] = state;
  useDebounceCallback(value, options);
  useEffect(() => {
    if (initialValue === value) return;
    setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);
  return state;
}
