import { TERMS_PAGE } from '../routes';

export const FOOTER = {
  fund: {
    about: 'https://nakedheart.ru/about',
    projects: 'https://nakedheart.ru/projects',
    forWho: 'https://nakedheart.ru/who-we-work-for',
    achievements: 'https://nakedheart.ru/achievements',
    reports: 'https://nakedheart.ru/about#documents',
    donate: 'https://nakedheart.ru/donate',
  },
  resources: {
    foundation: 'https://nakedheart.ru',
    foundationEn: 'https://nakedheart.ru/en',
    online: 'https://nakedheart.online',
  },
  socialNetworks: {
    // fb: 'https://www.facebook.com/NHFcharity',
    // ig: 'https://instagram.com/nakedheartfoundation',
    vk: 'https://vk.com/nhfcharity',
    ok: 'https://ok.ru/nakedheart',
    // youtube: 'https://www.youtube.com/user/nakedheartfoundation',
    // twitter: 'https://twitter.com/nakedheart_ru',
    vb: 'https://vb.me/nakedheartfoundation',
  },
  contacts: { mail: 'mailto:info@nakedheart.ru' },
  // terms: 'https://nakedheart.ru/terms',
  terms: TERMS_PAGE,
};

export const ARCHIVE = {
  description: {
    moreInfo: 'https://nakedheart.ru/about',
  }
};
