import { ProgramSection, ProgramSpeech, Speaker } from '../../services/models';
import { ClassName } from '../../components/types';
import { usePlural } from '../../localization/usePlural';
import classnames from 'classnames';
import css from './scheduleTable.module.scss';
import { MaybeLink, TileItem } from '../../components/elements/Elements';
import { speakerDetailedRoute } from '../../routes';
import { Box } from '@material-ui/core';
import React from 'react';
import { concatTime } from '../../hooks/moment';


export interface SectionEvent extends ProgramSection {
  year: number | string | undefined;
  start: Date;
  end: Date;
}

export function SpeakersRow({ className, speakers, showOrganization, size, clickable = false }: ClassName & {
  speakers?: Speaker[], showOrganization?: boolean, size?: 'small' | 'medium', clickable?: boolean
}) {
  const { t } = usePlural();
  if (!speakers || !speakers.length) return null;
  const item = speakers[0];
  const oneItem = speakers.length === 1;
  const subtitle = speakers.length === 1
    ? (showOrganization ? item.organization : null)
    : t('program.more_speakers', { count: speakers.length - 1 });
  const cn = classnames(css.SpeakerCard, css.SpeakersRow, className, {
    [css.small]: size === 'small',
    [css.medium]: size === 'medium',
    [css.twoLine]: subtitle
  });
  return (
    <TileItem
      className={cn}
      link={clickable && oneItem ? speakerDetailedRoute(item.id) : undefined}
      contentLink={clickable && !oneItem ? speakerDetailedRoute(item.id) : undefined}
      target="_blank"
      size={size == null ? undefined : 'small'}
      leading={(
        <Box display="flex" alignItems="center">
          {speakers.slice(0, 5).map(i => (
            <MaybeLink
              key={i.id} className={classnames(css.SpeakerCard__image, 'image')}
              link={clickable && !oneItem ? speakerDetailedRoute(i.id) : undefined} target="_blank"
            >
              <img src={getSpeakerAvatar(i)} alt="ава спикера" />
            </MaybeLink>
          ))}
        </Box>
      )}
      title={item.full_name}
      subtitle={subtitle}
    />
  );
}

export function isCoffeeBreak(item: ProgramSection | ProgramSpeech): boolean {
  return item.is_coffee_break ?? false;
}

export function getSpeakerAvatar(item: Speaker): string {
  return item.avatar || item.image;
}

export function getAllSectionSpeakers(item: ProgramSection): Speaker[] {
  const dict = new Map<number, Speaker>();
  item.speakers?.forEach((i) => {
    if (!dict.has(i.id)) dict.set(i.id, i);
  });
  item.speeches?.forEach(s => s.speakers?.forEach(i => {
    if (!dict.has(i.id)) dict.set(i.id, i);
  }));
  return Array.from(dict.values());
}

export function prepareSectionEvent(item: ProgramSection, year: number | string | undefined): SectionEvent {
  return {
    ...item,
    year,
    start: concatTime(item.date, item.start_at),
    end: concatTime(item.date, item.finish_at),
  };
}
