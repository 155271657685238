import React, { DOMAttributes, forwardRef } from 'react';
import classnames from 'classnames';
import { Backdrop, CircularProgress } from '@material-ui/core';

import './pageLayout.scss';
import AppBar from '../appBar/AppBar';
import { ClassName, Elem } from '../../types';
import Footer from '../footer/Footer';
import { useScrollToTop } from '../../../hooks/react';
import { useDocumentTitle } from '../../../hooks/windowHooks';

const PageLayout = forwardRef<HTMLDivElement, Elem & {
  pageTitle?: string, isLoading?: boolean, loaderColor?: 'primary' | 'secondary',
} & DOMAttributes<HTMLDivElement>>((props, ref) => {
  const { className, pageTitle, children, isLoading = false, loaderColor, ...others } = props;
  useDocumentTitle(pageTitle);
  useScrollToTop([]);
  return (
    <div ref={ref} className={classnames('PageLayout', className)} {...others}>
      <AppBar />
      <div className={'PageLayout__container'}>{children}</div>
      <Footer />
      {isLoading && <Loader color={loaderColor} />}
    </div>
  );
});
export default PageLayout;

export function PageContent({ className, children }: Elem) {
  return (<div className={classnames('pageContent', className)}>{children}</div>);
}

export function Image({ className, src, alt }: { className?: string, src: string, alt?: string }) {
  return (<div className={classnames('Image', className)}><img src={src} alt={alt} /></div>);
}

export function Photo({ className, src, alt }: { className?: string, src: string, alt?: string }) {
  return (<div className={classnames('Photo', className)}><img src={src} alt={alt} /></div>);
}

export function Loader({ className, color = 'primary' }: ClassName & { color?: 'primary' | 'secondary' }) {
  return (<Backdrop open className={classnames('Loader', className)}><CircularProgress color={color} /></Backdrop>);
}
