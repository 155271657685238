import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { isDebug } from '../utils/utils';
import { supportedLngs } from '../localization/localization';
import i18next from 'i18next';

export interface InitialData {
  pages?: { alias: string, enabled: boolean }[];
  switch_language?: boolean;
  footer: { resources_ru: FooterResource[], resources_en: FooterResource[] };
}

const pages = {
  'signup': true,
  'signup_conditions': true,
  'program': true,
  'speakers': true,
  'news': isDebug,
  'gallery': isDebug,
  'archive': true,
} as const;

export type PageAliases = keyof typeof pages;

export interface FooterResource {
  id: number;
  title: string;
  link: string;
}

type PagesContainer = Map<PageAliases, boolean>;

export interface AppConfig {
  pages: PagesContainer;
  language: { switchingEnabled: boolean };
  footer: { resources_ru: FooterResource[], resources_en: FooterResource[] };
}

const defaultConfig: AppConfig = {
  pages: new Map(Object.entries(pages) as [PageAliases, boolean][]),
  language: { switchingEnabled: isDebug && supportedLngs.length > 1 },
  footer: { resources_ru: [], resources_en: [] }
};
export const AppConfigContext = React.createContext<AppConfig>(defaultConfig);

export function useAppConfig() {
  return useContext(AppConfigContext);
}

export default function AppConfigProvider({ initialData, children }: {
  initialData: InitialData, children: ReactNode
}) {
  const [lang, setLang] = useState(i18next.language);
  const [data, setData] = useState<AppConfig & { pages_ru: PagesContainer, pages_en: PagesContainer }>(() => {
    const data = { ...defaultConfig, pages_ru: defaultConfig.pages, pages_en: filterEnPages(defaultConfig.pages) };
    if (initialData.pages?.length) {
      data.pages_ru = new Map(initialData.pages.map(i => [i.alias as PageAliases, i.enabled]));
      data.pages_en = filterEnPages(new Map(initialData.pages.map(i => [i.alias as PageAliases, i.enabled])));
    }
    if (initialData.switch_language != null) data.language.switchingEnabled = initialData.switch_language;
    data.footer = initialData.footer;
    return data;
  });
  useEffect(() => {
    const callback = () => {
      setLang(i18next.language);
    };
    i18next.on('languageChanged', callback);
    return () => i18next.off('languageChanged', callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setData(data => {
      const pages = (lang == null || lang === 'ru') ? data.pages_ru : data.pages_en;
      return ({ ...data, pages });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  return (<AppConfigContext.Provider value={data}>{children}</AppConfigContext.Provider>);
}

function filterEnPages(pages: PagesContainer): PagesContainer {
  // TODO: remove news from black list after en news done.
  const blackList = new Set<PageAliases>(['signup', 'signup_conditions', 'news']);
  return new Map(Array.from(pages.entries()).filter(([i]) => !blackList.has(i)));
}
