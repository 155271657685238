import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Swiper as ReactSwiper } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/core';
import 'swiper/swiper.min.css';

import { useMounted } from '../../hooks/react';

export { SwiperSlide } from 'swiper/react';
export { Swiper as SwiperClass } from 'swiper/core';

let firstTimeInited = false;

// use twice init to fix scroll extend error for slidesPerView="auto".
export const Swiper = ({ reInit = true, forwardedRef, onInit, ...props }: ReactSwiper & {
  reInit?: boolean,
  forwardedRef?: MutableRefObject<SwiperClass | undefined>
}) => {
  const mounted = useMounted();
  const [inited, setInited] = useState(!reInit || firstTimeInited);
  const handleInit = useCallback((e: SwiperClass) => {
    if (onInit) onInit(e);
    if (forwardedRef) forwardedRef.current = e;
    if (firstTimeInited) return;

    setTimeout(() => {
      firstTimeInited = true;
      if (mounted.current) setInited(true);
    }, 300);
  }, [forwardedRef, onInit, mounted]);
  useEffect(() => {
    return () => {
      if (forwardedRef) forwardedRef.current = undefined;
    };
  }, [forwardedRef]);
  return (<ReactSwiper key={`${inited}`} onInit={handleInit} {...props} />);
};
