import React, { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import theme from '../../theme/variables.module.scss';

export function useIsMobile(delta: number = 0) {
  return useMediaQuery({ maxWidth: getPixels(theme.mobileMaxWidth) + delta });
}

export function useIsTablet(delta: number = 0) {
  return useMediaQuery({ maxWidth: getPixels(theme.tabletMaxWidth) + delta });
}

export function useIsSDesktop(delta: number = 0) {
  return useMediaQuery({ maxWidth: getPixels(theme.sDesktopMaxWidth) + delta });
}

export function useIsFDesktop(delta: number = 0) {
  return useMediaQuery({ minWidth: getPixels(theme.fDesktopMaxWidth) + delta });
}

export function usePageMargin() {
  const isDesktop = useIsSDesktop(-10);
  const isMobile = useIsMobile();
  let margin = theme.lPageMargin;
  if (isDesktop) margin = theme.pageMargin;
  if (isMobile) margin = theme.mPageMargin;
  return getPixels(margin);
}

function getPixels(value: string): number {
  return +value.substring(0, value.length - 2);
}

export function Mobile({ children, invert, delta = 0 }: { children: ReactNode, invert?: boolean, delta?: number }) {
  let isMatch = useIsMobile(delta);
  if (invert) isMatch = !isMatch;
  if (!isMatch) return null;
  return <>{children}</>;
}
