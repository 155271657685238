import { generatePath } from 'react-router';
import { moment } from './hooks/moment';

export const ROOT = '/';
export const SIGNUP_PAGE = '/signup';
export const MAIN_PAGE = ROOT;
export const PROGRAM_PAGE = `${ROOT}program`;
export const PROGRAM_DETAILED_PAGE = `${PROGRAM_PAGE}/:year(\\d{4})?/:date?/:sectionId?`;
export const SPEAKERS_PAGE = `${ROOT}speakers`;
const SPEAKERS_YEAR_PAGE = `${ROOT}speakers?year=:year?`;
export const SPEAKER_DETAILED_PAGE = `${SPEAKERS_PAGE}/:speakerId`;
export const NEWS_PAGE = `${ROOT}news`;
export const NEWS_DETAILED_PAGE = `${NEWS_PAGE}/:newsId`;
export const GALLERY_PAGE = `${ROOT}gallery`;
export const GALLERY_DETAILED_PAGE = `${GALLERY_PAGE}/:imageId`;
export const ARCHIVE_PAGE = `${ROOT}archive`;
export const ARCHIVE_YEAR_PAGE = `${ROOT}archive/:year`;
export const TERMS_PAGE = `${ROOT}terms`;
export const SIGNUP_CONDITIONS_PAGE = `${ROOT}conditions`;


export function programRoute(year: number | string | undefined, date?: Date | string) {
  return generatePath(PROGRAM_DETAILED_PAGE, { year: year, date: date && programDateFormatter(date) });
}

export function programDetailedRoute(year: number | string | undefined, date: Date | string, sectionId?: number) {
  return generatePath(PROGRAM_DETAILED_PAGE, { year: year, date: programDateFormatter(date), sectionId });
}

export function speakerRoute(year?: number) {
  if (!year) return SPEAKERS_PAGE;
  return generatePath(SPEAKERS_YEAR_PAGE, { year });
}

export function speakerDetailedRoute(speakerId: number) {
  return generatePath(SPEAKER_DETAILED_PAGE, { speakerId });
}

export function newsDetailedRoute(newsId: number) {
  return generatePath(NEWS_DETAILED_PAGE, { newsId });
}

export function galleryDetailedRoute(imageId: number) {
  return generatePath(GALLERY_DETAILED_PAGE, { imageId });
}

export function archiveRoute(year?: number) {
  return generatePath(ARCHIVE_YEAR_PAGE, { year });
}

const programDateFormat = 'DD-MM-yyyy';

export function programDateFormatter(date: Date | string) {
  return moment(date).format(programDateFormat);
}

export function programDateParser(formatted?: string) {
  const date = moment(formatted, programDateFormat);
  if (date.isValid()) return date;
  return moment();
}

export function yearParser(year?: string | null): undefined | number {
  const value = year && +year;
  if (!value) return undefined;
  return value;
}
