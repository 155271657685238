import { HTMLAttributeAnchorTarget, MouseEvent, MouseEventHandler, useCallback } from 'react';
import { Location, LocationDescriptorObject } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
// @ts-ignore
import { normalizeToLocation, resolveToLocation } from 'react-router-dom/modules/utils/locationUtils';

export function useQuery<Params extends { [K in keyof Params]?: string } = {}>(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

function isModifiedEvent(event: MouseEvent) {
  return (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function normalizeLinkToLocation(to: string | undefined, currentLocation: Location): LocationDescriptorObject {
  if (typeof to === 'string' && httpRegex.test(to)) return { pathname: to };
  return normalizeToLocation(to, currentLocation);
}

const httpRegex = new RegExp('^(http(s?))://', 'i');
const validDomainEnd = new Set(['/', '?']);

export function isInternalLink(href: string | undefined) {
  if (!href || !httpRegex.test(href)) return true;
  const { origin } = window.location;
  const link = href.toLowerCase();
  if (!link.startsWith(origin.toLowerCase())) return false;
  return (link.length === origin.length) || validDomainEnd.has(link[origin.length]);
}

export function useLinkClick({ to, target, download, onClick, replace = false }: {
  to?: string | undefined, target?: HTMLAttributeAnchorTarget | undefined, download?: any, replace?: boolean,
  onClick?: MouseEventHandler | undefined,
  // navigate?: () => void,
}): { onClick: MouseEventHandler } {
  const history = useHistory();

  const handleLinkClick = useCallback((event: MouseEvent) => {
    try {
      if (onClick) onClick(event);
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }
    if (!to) return;
    const location = normalizeLinkToLocation(
      resolveToLocation(to, history.location),
      history.location
    );
    const href = location ? history.createHref(location) : '';

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!target || target === "_self") && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) && // ignore clicks with modifier keys
      isInternalLink(href) &&
      !download
    ) {
      event.preventDefault();
      const navigate = () => {
        const location = resolveToLocation(to, history.location);
        const method = replace ? history.replace : history.push;

        method(location);
      }

      navigate();
    }
  }, [to, replace, onClick, history, target, download/*, navigate*/]);

  return { onClick: handleLinkClick };
}
