import { useCallback, useRef, useState } from 'react';
import { Pagination } from '../services/models';

export function usePaginationState<T>() {
  const [currentPage, setCurrentPage] = useState<Pagination<T>>({ results: [], count: 0 });
  const items = useRef<T[]>(currentPage?.results ?? []);
  const reset = useCallback((page?: Pagination<T>) => {
    items.current = page?.results ?? [];
    setCurrentPage(page ?? { results: [], count: 0 });
  }, []);
  const append = useCallback((page: Pagination<T>) => {
    items.current = [...items.current, ...page.results];
    setCurrentPage(page);
  }, []);
  return { currentPage, items: items.current, reset, append };
}
