import { DefaultNamespace, Namespace, TFunction, useTranslation } from 'react-i18next';

export function usePlural<N extends Namespace = DefaultNamespace>() {
  const { t, i18n } = useTranslation<N>();
  const { language } = i18n;
  if (language !== 'ru') return { t, i18n };

  // @ts-ignore
  const ruPlural: TFunction<N> = (key, options) => {
    const { count = 0 } = options || {};
    const postfix = plural(count || 0, '', 'few', 'many');
    const fullKey = postfix ? `${key}_${postfix}` : key;
    return t(fullKey as any, t(key, options), options);
  }
  return { t: ruPlural, i18n };
}

function plural(number: number, one: string, two: string, five: string): string {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}
