import { DependencyList, EffectCallback, RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { useWindowEvent } from './windowHooks';
import { useDebounceCallback } from './debounce';

export function useMounted(): { readonly current: boolean } {
  const mounted = useRef<boolean>(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted;
}

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList): void {
  const inited = useRef(false);
  useEffect(() => {
    if (!inited.current) {
      inited.current = true;
      return;
    }
    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useLoading(initialState: boolean = false) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(initialState);
  const ref = useRef(0);

  const withLoading = useCallback(<T>(promise: Promise<T>) => {
    if (!mounted.current) return promise;
    ref.current += 1;
    if (ref.current === 1) setLoading(true);
    return promise.finally(() => {
      ref.current -= 1;
      if (!mounted.current) return;
      if (ref.current === 0) setLoading(false);
    });
  }, [mounted]);
  return { loading, withLoading };
}

export function useToggle(initialValue: boolean = false) {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback((value?: boolean | any) => {
    setValue(currentValue => typeof value === "boolean" ? value : !currentValue);
  }, []);
  return [value, toggle] as const;
}


export function useScrollToTop(deps: DependencyList = []) {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function scrollToRef(ref: RefObject<HTMLElement>, {
  behavior = 'smooth',
  verticalCondition = 'onlyUp'
}: { behavior?: ScrollBehavior, verticalCondition?: null | 'onlyUp' } = {}) {
  if (!ref.current) return;
  if (verticalCondition === 'onlyUp') {
    const { top } = ref.current.getBoundingClientRect();
    if (top >= 0) return;
  }
  window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior });
}

export function useElementHeight<T extends HTMLElement>(options: undefined | null | {
  refObject?: RefObject<T>, getElementHeight?: (item?: HTMLElement | null) => number | undefined,
} = {}, deps: DependencyList = []) {
  const { refObject, getElementHeight: getHeight = getElementHeight } = options || {};
  const ref = useRef<T>(refObject?.current ?? null);
  const [updated, setUpdated] = useState(0);
  const [height, setHeight] = useState(() => getHeight(ref.current));

  useEffect(() => {
    setUpdated(updated + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, getHeight, ...deps]);
  useWindowEvent({ type: 'resize', listener: () => setUpdated(updated + 1) }, [updated]);
  useDebounceCallback(updated, {
    callOnInit: true,
    timeoutMs: 40,
    callback: () => {
      const h = getHeight(ref.current ?? refObject?.current);
      if (h !== height) setHeight(h);
    }
  });
  return { ref: refObject ?? ref, height };
}

export function useElementAnimatedHeight<T extends HTMLElement>(deps: DependencyList = []) {
  const { ref, height } = useElementHeight<T>({ getElementHeight: getElementChildrenHeight }, deps);
  return { ref, height, style: { height, transition: 'height 0.4s ease-out', overflow: 'hidden' } };
}

export function getElementHeight(item?: HTMLElement | null): number | undefined {
  if (item == null) return undefined;
  return item.clientHeight;
}

export function getElementScrollHeight(item?: HTMLElement | null): number | undefined {
  if (item == null) return undefined;
  return item.scrollHeight;
}

function getElementChildrenHeight(item?: HTMLElement | null): number | undefined {
  if (item == null) return undefined;
  const childrenBottomOffset = Array.from(item.children).map((i) => {
    const rect = i.getBoundingClientRect();
    return rect.top + rect.height;
  }).reduce((res, i) => i > res ? i : res, Number.MIN_SAFE_INTEGER);
  const top = item.getBoundingClientRect().top;
  return childrenBottomOffset - top;
}
