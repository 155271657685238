import React, { AnchorHTMLAttributes } from 'react';
import classnames from 'classnames';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButton,
  IconButtonProps,
  Typography,
} from '@material-ui/core';

import './buttons.scss';
import { RightArrowIcon } from '../Icons';
import { useLinkClick } from '../../hooks/reactRouter';

export type ButtonProps = AnchorHTMLAttributes<'button'> & MuiButtonProps;

export default function Button({ onClick, ...props }: ButtonProps) {
  const { href, target, download } = props;
  const { onClick: handleClick } = useLinkClick({ to: href, target, download, onClick });
  return (<MuiButton onClick={handleClick} referrerPolicy="no-referrer" {...props} />);
}

export function OutlineButton(props: ButtonProps) {
  return (<Button variant='outlined' color="primary" {...props} />);
}

export function ContainButton(props: ButtonProps) {
  return (<Button variant='contained' color="secondary" {...props} />);
}

export function RightArrowButton({ className, children, ...props }: ButtonProps) {
  return (
    <Button
      className={classnames('RightArrowButton', className)} color="primary"
      endIcon={<RightArrowIcon color="currentColor" />}
      {...props}
    >
      <Typography>{children}</Typography>
    </Button>
  );
}

export function LeftArrowButton({ className, children, ...props }: ButtonProps) {
  return (
    <Button
      className={classnames('RightArrowButton', 'leftArrow', className)} color="secondary"
      startIcon={<RightArrowIcon color="currentColor" />}
      {...props}
    >
      <Typography>{children}</Typography>
    </Button>
  );
}

export function RightArrowIconButton({ className, children, ...props }: IconButtonProps) {
  return (
    <IconButton className={classnames('RightArrowIconButton', className)} {...props}>
      <RightArrowIcon />
    </IconButton>
  );
}

export function LeftArrowIconButton({ className, ...props }: IconButtonProps) {
  return (<RightArrowIconButton className={classnames('leftArrow', className)} {...props} />);
}
