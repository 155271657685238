import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { Box, ThemeProvider } from '@material-ui/core';

import './App.css';
import {
  ARCHIVE_PAGE,
  ARCHIVE_YEAR_PAGE,
  GALLERY_DETAILED_PAGE,
  GALLERY_PAGE,
  MAIN_PAGE,
  NEWS_DETAILED_PAGE,
  NEWS_PAGE,
  PROGRAM_DETAILED_PAGE,
  PROGRAM_PAGE,
  SIGNUP_CONDITIONS_PAGE,
  SIGNUP_PAGE,
  SPEAKER_DETAILED_PAGE,
  SPEAKERS_PAGE,
  TERMS_PAGE
} from './routes';
import { muiTheme } from './theme/muiTheme';
import PageLayout, { Loader } from './components/layout/pageLayout/PageLayout';
import { MomentContext, useMomentProvider } from './hooks/moment';
import { useAppConfig } from './components/AppConfigProvider';
import { useLanguageChanged } from './hooks/localization';
import { resetForumData } from './features/mainPage/forumDataSlice';

const MainPage = lazy(() => import('./features/mainPage/MainPage'));
const ProgramPage = lazy(() => import('./features/program/Program'));
const SpeakersPage = lazy(() => import('./features/speakers/SpeakersPage'));
const NewsPage = lazy(() => import('./features/news/NewsPage'));
const GalleryPage = lazy(() => import('./features/gallery/GalleryPage'));
const ArchivePage = lazy(() => import('./features/archive/ArchivePage'));
const SignUpPage = lazy(() => import('./features/auth/SignUpPage'));
const TermsPage = lazy(() => import('./features/terms/TermsPage'));
const SignUpConditionsPage = lazy(() => import('./features/terms/SignUpConditionsPage'));


export default function App() {
  const { language: { switchingEnabled } } = useAppConfig();
  const [i18Initialized, setI18Initialized] = useState(i18next.isInitialized ?? false);
  useEffect(() => {
    const callback = () => {
      const lang = (new URLSearchParams(window.location.search)).get('lang');

      setI18Initialized(true);
      i18next.off('initialized', callback);
      // if (switchingEnabled || i18next.language === 'ru') return;
      i18next.changeLanguage(lang ?? 'ru');
    };
    i18next.on('initialized', callback);
    return () => i18next.off('initialized', callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ThemeProvider theme={muiTheme}>
      <Suspense fallback={<Loader />}>
        {/*FIXME: ??? swiper now working after suspense. fixed after resize. */}
        <Router key={`${i18Initialized}`}>
          <AppRoutes />
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}

function AppRoutes() {
  const { pages } = useAppConfig();
  const dispatch = useDispatch();
  const history = useHistory();
  const moment = useMomentProvider();
  useLanguageChanged(() => {
    if (history.location.pathname === MAIN_PAGE) return;
    dispatch(resetForumData());
  });

  const lang = (new URLSearchParams(window.location.search)).get('lang');

  let langParam = '';

  if (lang !== 'ru') {
    langParam += `?lang=${lang}`
  }

  return (
    <MomentContext.Provider value={moment}>
      <Suspense fallback={<PageLayout isLoading={true}><Box minHeight="90vh" /></PageLayout>}>
        <Switch>
          <Route exact path={MAIN_PAGE} component={MainPage} />
          <Route exact path={[PROGRAM_PAGE, PROGRAM_DETAILED_PAGE]} component={ProgramPage} />
          <Route exact path={[SPEAKERS_PAGE, SPEAKER_DETAILED_PAGE]} component={SpeakersPage} />
          {pages.get('news') && <Route exact path={[NEWS_PAGE, NEWS_DETAILED_PAGE]} component={NewsPage} />}
          <Route exact path={[GALLERY_PAGE, GALLERY_DETAILED_PAGE]} component={GalleryPage} />
          <Route exact path={[ARCHIVE_PAGE, ARCHIVE_YEAR_PAGE]} component={ArchivePage} />
          {pages.get('signup') && <Route exact path={SIGNUP_PAGE} component={SignUpPage} />}
          <Route exact path={TERMS_PAGE} component={TermsPage} />
          {pages.get('signup_conditions') &&
            <Route exact path={SIGNUP_CONDITIONS_PAGE} component={SignUpConditionsPage} />}
          <Redirect to={MAIN_PAGE} />
        </Switch>
      </Suspense>
    </MomentContext.Provider>
  );
}
