import { DependencyList, useEffect, useRef } from 'react';

type WindowEventType = keyof WindowEventMap;
type WindowEventListener<K extends WindowEventType> = (this: Window, ev: WindowEventMap[K]) => any;

export function useWindowEvent<K extends WindowEventType>(
  options: { type: K, listener: WindowEventListener<K>, callOnInit?: boolean }, deps?: DependencyList
) {
  const { type, listener, callOnInit = false, } = options;
  const callbackRef = useRef(listener);

  useEffect(() => {
    callbackRef.current = listener;
  }, [listener]);

  useEffect(() => {
    if (callbackRef.current == null) return;

    const callback: WindowEventListener<K> = function (e) {
      return callbackRef.current.call(this, e);
    }
    window.addEventListener(type, callback);
    if (callOnInit) callback.call(window, {} as any);
    return () => window.removeEventListener(type, callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? [type]);
}

export function useDocumentTitle(title?: string, restore: boolean = true) {
  const initial = useRef({ title: document.title });

  useEffect(() => {
    if (title == null) return;
    document.title = title;
    const initialTitle = initial.current.title;
    return () => {
      if (!restore) return;
      document.title = initialTitle;
    };
  }, [title, restore]);
}
