import i18next from 'i18next';
import {
  ArchiveForumData,
  DynamicPage,
  Filters,
  ForumData,
  GalleryImage,
  News,
  Pagination,
  Program,
  SearchResult,
  SearchSuggestion,
  SignUpData,
  Speaker
} from './models';

const HOST = '/';
const API = `${HOST}api/v1/`;

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

class ApiProvider {
  protected static instance: ApiProvider;

  protected constructor() {
  }

  public static getInstance() {
    if (!this.instance) this.instance = new this();
    return this.instance;
  }

  fetch(input: Request | string, init?: RequestInit | undefined) {
    const initData = init || {};
    let { headers = {}, /*method*/ } = initData;
    headers = { ...defaultHeaders, ...headers };
    // if (!csrfSafeMethod(method)) {
    //   const cookies = new Cookies();
    //   // @ts-ignore
    //   headers['X-CSRFToken'] = cookies.get('csrftoken');
    // }
    initData.headers = Object.fromEntries(Object.entries(headers).filter(([_, value]) => value));
    return fetch(input, initData);
  }


  async apiResponse<T>(response: Response): Promise<T> {
    if (response.ok) return response.json();
    if (this.isResponseClientError(response)) throw (await this.parseApiErrors(response));
    throw ApiErrorsFactory({ error: getDefaultErrorMessage() }, response.status);
  }

  parseApiErrors<T>(response: Response): Promise<ApiErrors<T>> {
    return response.json().then((data) => ApiErrorsFactory<T>(data, response.status));
  }

  isResponseClientError(response: Response) {
    return response.status >= 400 && response.status < 500;
  }
}

class Provider extends ApiProvider {
  async fetchForumData(): Promise<ForumData> {
    const lang = (new URLSearchParams(window.location.search)).get('lang') ?? 'ru'
    const response = await this.fetch(`${API}forum?lang=${lang}`);
    return this.apiResponse(response);
  }

  async fetchSignUpData(): Promise<SignUpData> {
    const response = await this.fetch(`${API}signup`);
    return this.apiResponse(response);
  }

  async fetchProgram(year?: number | string | null): Promise<Program> {
    const url = year ? `${API}forums/${year}/program` : `${API}forum/program`;
    const response = await this.fetch(url);
    return this.apiResponse(response);
  }

  async fetchSpeakers({ year, next }: {
    year?: number | string | null, next?: string | null
  } = {}): Promise<Pagination<Speaker>> {
    let url: string;
    if (next) {
      url = next;
    } else {
      url = `${API}speakers`;
      if (year) url = `${url}?year=${year}`;
    }
    const response = await this.fetch(url);
    return this.apiResponse(response);
  }

  async fetchSpeakerFilters(): Promise<Filters> {
    const response = await this.fetch(`${API}speakers/filters`);
    return this.apiResponse(response);
  }

  async fetchSpeaker(speakerId: number): Promise<Speaker> {
    const response = await this.fetch(`${API}speakers/${speakerId}`);
    return this.apiResponse(response);
  }

  async fetchNewsList({ year, next }: {
    year?: number | string | null, next?: string | null
  } = {}): Promise<Pagination<News>> {
    let url: string;
    if (next) {
      url = next;
    } else {
      url = `${API}news`;
      if (year) url = `${url}?year=${year}`;
    }
    const response = await this.fetch(url);
    return this.apiResponse(response);
  }

  async fetchNewsFilters(): Promise<Filters> {
    const response = await this.fetch(`${API}news/filters`);
    return this.apiResponse(response);
  }

  async fetchNews(newsId: number): Promise<News> {
    const response = await this.fetch(`${API}news/${newsId}`);
    return this.apiResponse(response);
  }

  async fetchGalleryList({ year, next }: {
    year?: number | string | null, next?: string | null
  } = {}): Promise<Pagination<GalleryImage>> {
    let url: string;
    if (next) {
      url = next;
    } else {
      url = `${API}gallery/images`;
      if (year) url = `${url}?year=${year}`;
    }
    const response = await this.fetch(url);
    return this.apiResponse(response);
  }

  async fetchGalleryImage(imageId: number): Promise<GalleryImage> {
    const response = await this.fetch(`${API}gallery/images/${imageId}`);
    return this.apiResponse(response);
  }


  async fetchGalleryFilters(): Promise<Filters> {
    const response = await this.fetch(`${API}gallery/filters`);
    return this.apiResponse(response);
  }

  async fetchArchives(): Promise<ArchiveForumData> {
    const response = await this.fetch(`${API}archives`);
    return this.apiResponse(response);
  }

  async fetchSearchSuggestions(): Promise<SearchSuggestion[]> {
    const response = await this.fetch(`${API}search_suggestions`);
    return this.apiResponse(response);
  }

  async search({ value = '', next }: { value?: string, next?: string | null } = {}): Promise<Pagination<SearchResult>> {
    const url = next ? next : `${API}search?q=${value}`;
    const response = await this.fetch(url);
    return this.apiResponse(response);
  }

  async fetchTerms(): Promise<DynamicPage> {
    const response = await this.fetch(`${API}terms`);
    return this.apiResponse(response);
  }

  async fetchSignUpConditions(): Promise<DynamicPage> {
    const response = await this.fetch(`${API}signup_conditions`);
    return this.apiResponse(response);
  }
}

const apiProvider = Provider.getInstance() as Provider;

export default apiProvider;


export function ApiErrorsFactory<T>(json: T, responseStatus: number = 400): ApiErrors<T> {
  return new ApiErrors<T>(json);
}

export function getDefaultErrorMessage() {
  return i18next.t('common.default_error');
}

export type ApiErrorFields<Values> = {
  [K in keyof Values]?: string | string[];
};

export class ApiErrors<T> {
  readonly errors: ApiErrorFields<T>;

  constructor(json: ApiErrorFields<T>) {
    this.errors = json;
  }

  getErrorMessage(): string | undefined {
    return this.getError('error') || this.getError('detail') || this.getError('non_field_error');
  }

  getError(fieldName: string): string | undefined {
    if (!this.errors || !fieldName) return undefined;
    // @ts-ignore
    let err = this.errors[fieldName];
    if (Array.isArray(err)) err = err.join('\n');
    return err?.toString();
  }

  getStringErrors() {
    const errors = { ...this.errors } as any;
    for (const key in errors) {
      errors[key] = this.getError(key);
    }
    const error = this.getErrorMessage();
    if (error && !errors.error) errors.error = error;
    return errors;
  }
}
