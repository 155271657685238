import { createStyles, createTheme } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import theme from './variables.module.scss';

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    // adds the custom breakpoint
    mobileMaxWidth: true;
    tabletMaxWidth: true;
    hTabletMaxWidth: true;
    sDesktopMaxWidth: true;
    mDesktopMaxWidth: true;
    lDesktopMaxWidth: true;
    fDesktopMaxWidth: true;
  }
}

const palette = createPalette({
  primary: { main: theme.primaryColor },
  secondary: { main: theme.secondaryColor },
});


const breakpoints = createBreakpoints({
  unit: 'px',
  down: (key) => {
    let value = typeof key === 'number' ? key : breakpoints.values[key];
    return `@media (max-width:${value}px)`;
  },
  values: {
    mobileMaxWidth: getPixels(theme.mobileMaxWidth),
    tabletMaxWidth: getPixels(theme.tabletMaxWidth),
    hTabletMaxWidth: getPixels(theme.hTabletMaxWidth),
    sDesktopMaxWidth: getPixels(theme.sDesktopMaxWidth),
    mDesktopMaxWidth: getPixels(theme.mDesktopMaxWidth),
    lDesktopMaxWidth: getPixels(theme.lDesktopMaxWidth),
    fDesktopMaxWidth: getPixels(theme.fDesktopMaxWidth),
  },
});

function getPixels(value: string): number {
  return +value.substring(0, value.length - 2);
}

const disabledButton = { color: '#ffffffb2', backgroundColor: theme.disabledColor };
const outlinedButton = {
  border: '4px solid currentColor',
  '&:hover': { border: '4px solid currentColor' },
  '&$disabled': { ...disabledButton, border: '4px solid currentColor', borderColor: theme.disabledColor },
};
const buttonStyles = createStyles({
  root: {
    padding: '8px 20px',
    minHeight: 43,
    borderWidth: 4,
    borderRadius: 56,
    '&$disabled': disabledButton,
    letterSpacing: 0,
    fontFamily: theme.accentFontFamily,
    fontSize: 32,
    fontWeight: 400,
    lineHeight: 1,
    [breakpoints.up('fDesktopMaxWidth')]: { fontSize: 48 },
  },
  outlined: {
    padding: '4px 16px',
    ...outlinedButton,
    '&>$label': { transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' },
    '&:hover': { border: '4px solid currentColor', '&>$label': { opacity: 0.4 } },
  },
  outlinedPrimary: outlinedButton,
  outlinedSecondary: outlinedButton,
  contained: {
    color: '#fff',
    backgroundColor: theme.secondaryColor,
    '&$disabled': disabledButton,
  },
  containedSecondary: { '&:hover': { backgroundColor: '#D94865' } },
  text: {
    minHeight: 32,
    textTransform: 'none',
    '&>$label': { marginBottom: -2 },
    '&>$label>$endIcon': { marginBottom: 0 },
  },
  label: {
    marginBottom: -5,
  },
  endIcon: { marginLeft: 14, marginBottom: 5 },
});
const iconButtonStyles = createStyles({
  colorPrimary: {
    color: theme.backgroundColor,
    backgroundColor: palette.primary.main,
    '&:hover': { backgroundColor: palette.primary.dark, },
    '@media (hover: none)': { '&:hover': { backgroundColor: `${palette.primary.dark} !important`, } },
  },
  colorSecondary: {
    color: theme.backgroundColor,
    backgroundColor: palette.secondary.main,
    '&:hover': { backgroundColor: palette.secondary.dark },
    '@media (hover: none)': { '&:hover': { backgroundColor: `${palette.secondary.dark} !important`, } },
  },
});

const typographyStyles = createStyles({
  root: {
    '&.underline': {
      position: 'relative',
      display: 'inline-block',
    },
    '&.underline:before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      right: 4,
      bottom: 0,
      height: 1,
      borderRadius: 20,
      background: 'currentColor',
    },
  },
  h1: {
    color: theme.primaryColor,
    fontFamily: theme.accentFontFamily,
    fontSize: 62,
    lineHeight: 1,
    letterSpacing: '-0.02em',
    '&.underline:before': { height: 6 },
    [breakpoints.up('fDesktopMaxWidth')]: { fontSize: 80 },
    [breakpoints.down(breakpoints.values['mDesktopMaxWidth'] - 20)]: { fontSize: 48 },
    [breakpoints.down('mobileMaxWidth')]: { fontSize: 40 },
  },
  h2: {
    color: theme.primaryColor,
    fontFamily: theme.accentFontFamily,
    fontSize: 62,
    lineHeight: 1,
    letterSpacing: '-0.02em',
    '&.underline:before': { height: 6 },
    [breakpoints.up('fDesktopMaxWidth')]: { fontSize: 80 },
    [breakpoints.down(breakpoints.values['mDesktopMaxWidth'] - 20)]: { fontSize: 48 },
    [breakpoints.down('mobileMaxWidth')]: { fontSize: 40 },
  },
  h3: {
    color: theme.primaryColor,
    fontFamily: theme.accentFontFamily,
    fontSize: 35,
    lineHeight: 1,
    '&.underline:before': { height: 3 },
    [breakpoints.up('fDesktopMaxWidth')]: { fontSize: 48 },
    [breakpoints.down(breakpoints.values['mDesktopMaxWidth'] - 20)]: { fontSize: 26 },
    [breakpoints.down('mobileMaxWidth')]: { fontSize: 22 },
  },
  h4: {
    color: theme.primaryColor,
    fontFamily: theme.accentFontFamily,
    fontSize: 32,
    lineHeight: 1,
    letterSpacing: 0,
    '&.underline:before': { height: 3 },
    [breakpoints.up('fDesktopMaxWidth')]: { fontSize: 48 },
    [breakpoints.down(breakpoints.values['mDesktopMaxWidth'] - 20)]: { fontSize: 26 },
    [breakpoints.down('mobileMaxWidth')]: { fontSize: 22 },
  },
  h5: {
    color: theme.secondaryColor,
    fontFamily: theme.accentFontFamily,
    fontSize: 92,
    lineHeight: 1,
    '&.underline:before': { height: 6 },
    [breakpoints.up('fDesktopMaxWidth')]: { fontSize: 110 },
    [breakpoints.down(breakpoints.values['mDesktopMaxWidth'] - 20)]: { fontSize: 74 },
    [breakpoints.down('mobileMaxWidth')]: { fontSize: 62 },
  },
  h6: {
    color: theme.secondaryColor,
    fontFamily: theme.accentFontFamily,
    fontSize: 48,
    lineHeight: 1,
    '&.underline:before': { height: 4 },
  },
  subtitle1: {
    color: theme.primaryColor,
    fontFamily: theme.accentFontFamily,
    fontSize: 26,
    lineHeight: 1,
    [breakpoints.up('fDesktopMaxWidth')]: { fontSize: 35 },
    [breakpoints.down('mobileMaxWidth')]: { fontSize: 22 },
  },
  subtitle2: {
    fontFamily: theme.fontFamily,
    fontSize: 25,
    fontWeight: 400,
    lineHeight: 1.28,
    letterSpacing: 0,
    [breakpoints.down('mobileMaxWidth')]: { fontSize: 19, lineHeight: '22px' },
  },
  body1: {
    fontFamily: theme.fontFamily,
    fontSize: 19,
    lineHeight: 1.26,
    letterSpacing: 0,
    [breakpoints.down('sDesktopMaxWidth')]: { fontSize: 16, lineHeight: '24px' },
  },
  caption: {
    color: theme.disabledColor,
    fontFamily: theme.fontFamily,
    fontSize: 15,
    lineHeight: 1.25,
  },
});

const stepperStepStyles = createStyles({
  vertical: {
    minHeight: 74,
    '&>.MuiStepLabel-root.MuiStepLabel-alternativeLabel': { flexDirection: 'row', alignItems: 'flex-start' },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': { marginTop: 0, marginLeft: 24, marginBottom: -4 },
    '&>.MuiStepConnector-alternativeLabel': { top: 'initial', bottom: '100%', left: 'initial', right: 'initial' },
  },
});

export const muiTheme = createTheme({
  palette: palette,
  breakpoints: breakpoints,
  props: {
    MuiButton: { disableElevation: true },
  },
  overrides: {
    MuiButton: buttonStyles,
    MuiIconButton: iconButtonStyles,
    MuiTypography: typographyStyles,
    MuiStepper: createStyles({ root: { padding: 0 } }),
    MuiStep: stepperStepStyles,
    MuiStepConnector: createStyles({
      root: { borderColor: '#D9DEE9', borderWidth: 4, },
      vertical: { height: 'calc(100% - 24px)', marginLeft: 10, padding: 0 },
      alternativeLabel: { top: 10, left: 'calc(-50% + 12px)', right: 'calc(50% + 12px)' },
      line: { borderColor: 'inherit' },
      lineHorizontal: { borderTopWidth: 4 },
      lineVertical: { minHeight: 50, height: '100%', borderLeftWidth: 4 },
    }),
    MuiStepButton: createStyles({
      vertical: {
        margin: '-8px -16px -4px',
        padding: '8px 16px 4px',
        borderRadius: 50,
        textAlign: 'left',
      },
      horizontal: {
        margin: '-12px -16px -8px',
        padding: '12px 16px 8px',
        borderRadius: 50,
        textAlign: 'center',
      },
    }),
    MuiDialog: createStyles({
      root: {
        // '&>.MuiBackdrop-root': { backgroundColor: 'rgba(38, 38, 38, 0.3)' },
      },
      paper: {
        'overflow-y': 'initial',
        maxWidth: '65%',
        margin: '72px 80px 32px',
        borderRadius: 8,
        boxShadow: '0px 4px 16px rgba(0, 33, 105, 0.3)',
        [breakpoints.down(breakpoints.values['sDesktopMaxWidth'] + 20)]: { maxWidth: '74%' },
        [breakpoints.down(breakpoints.values['sDesktopMaxWidth'] - 200)]: { maxWidth: 'calc(100% - 2 * 80px)' },
        [breakpoints.down('tabletMaxWidth')]: { maxWidth: 'calc(100% - 2 * 48px)', margin: '24px 48px 120px' },
        [breakpoints.down('mobileMaxWidth')]: {
          minWidth: '100%',
          minHeight: '100%',
          margin: 0,
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
      paperScrollPaper: { maxHeight: 'calc(100% - 120px)' },
    }),
    MuiDialogContent: createStyles({
      root: {
        padding: 48,
        boxSizing: 'border-box',
        [breakpoints.down('mobileMaxWidth')]: { padding: '26px 16px 108px' },
      },
    }),
    MuiBackdrop: createStyles({ root: { backgroundColor: 'rgba(38, 38, 38, 0.3)' } }),
    MuiCheckbox: createStyles({
      root: { '&.MuiCheckbox-root': { padding: 8, backgroundColor: 'transparent' }, },
    }),
    MuiRadio: createStyles({
      root: { '&.MuiRadio-root': { padding: 8, backgroundColor: 'transparent' }, },
    }),
  },
});
