import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Dialog as MuiDialog, DialogContent, DialogProps, IconButton } from '@material-ui/core';

import css from './dialog.module.scss';
import { CloseIcon } from '../Icons';

export default function Dialog({ className, children, ...props }: DialogProps) {
  const { onClose } = props;
  const handleCloseBtnClick = useCallback((e) => {
    if (onClose) onClose(e, 'backdropClick');
  }, [onClose]);
  return (
    <MuiDialog className={classnames(css.Dialog, className)} scroll="body" {...props}>
      <IconButton className={classnames(css.closeBtn, 'MuiDialog-closeBtn')}
                  onClick={handleCloseBtnClick}><CloseIcon /></IconButton>
      <DialogContent>
        {children}
      </DialogContent>
    </MuiDialog>
  );
}
