import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Link as RouterLink } from '../../elements/Elements';


import './footer.scss';
import { ClassName, Elem } from '../../types';
import { FBIcon, IGIcon, OKIcon, TwitterIcon, VbIcon, VKIcon, WhatsappIcon, YouTubeIcon } from '../../Icons';
import { FOOTER } from '../../../config/links';
import { useAppPageLinks, useSwitchLanguage } from '../appBar/AppBar';
import { RightArrowButton } from '../../buttons/Buttons';
import Dialog from '../../dialog/Dialog';
import { isMailingFormOpenSelector, toggleMailingForm } from '../../../app/appSlice';
import { FooterResource, useAppConfig } from '../../AppConfigProvider';

export default function Footer({ className }: ClassName) {
  const dispatch = useDispatch();
  const { t, i18n: { language } } = useTranslation();
  const resources = useFooterResources();
  const isRu = language === 'ru';
  const links = useAppPageLinks();
  const { languageToSwitch, switchLanguage, show: showLang } = useSwitchLanguage();
  const isSubscribeFormOpen = useSelector(isMailingFormOpenSelector);
  const toggleSubscribeForm = useCallback(() => {
    dispatch(toggleMailingForm());
  }, [dispatch]);
  // const handleSubscribe = useCallback((values) => {
  //   console.log('submit email form', { values });
  // }, []);
  return (
    <footer className={classnames('Footer', className, { footer_en: !isRu })}>
      <Box className={'Footer__row'} style={isRu ? { '--resources_count': resources.length } as any : undefined}>
        {isRu && (
          <Box className={'Footer__box Footer__subscribe'}>
            <Typography className={'Footer__header'} variant="h4" color="inherit">
              {t('footer.subscribe_to_mailing.title')}
            </Typography>
            <RightArrowButton
              className={'Footer__subscribeBtn'} variant="contained" color="primary"
              onClick={toggleSubscribeForm}
            >
              {t('footer.subscribe_to_mailing.form_button')}
            </RightArrowButton>
            {/*<Formik*/}
            {/*  initialValues={{ email: '' }}*/}
            {/*  validationSchema={yup.object().shape({ email: yup.string().email().required().label('E-mail') })}*/}
            {/*  onSubmit={handleSubscribe}*/}
            {/*>*/}
            {/*  <Form className={'Footer__subscribeForm'}>*/}
            {/*    <Field id="email" name="email" type="email" placeholder={t('footer.subscribe_to_mailing.email')} />*/}
            {/*    <ErrorMessage name="email">{(val) => <Typography>{val}</Typography>}</ErrorMessage>*/}
            {/*    <IconButton type="submit"><RightArrowIcon /></IconButton>*/}
            {/*  </Form>*/}
            {/*</Formik>*/}
          </Box>
        )}
        {/*<Box className={'Footer__box Footer__fund'}>*/}
        {/*  <Typography className={'Footer__header'} variant="h4" color="inherit">{t('footer.fund.title')}</Typography>*/}
        {/*  <Link href={FOOTER.fund.about}>{t('footer.fund.about')}</Link>*/}
        {/*  <Link href={FOOTER.fund.projects}>{t('footer.fund.projects')}</Link>*/}
        {/*  <Link href={FOOTER.fund.forWho}>{t('footer.fund.forWho')}</Link>*/}
        {/*  <Link href={FOOTER.fund.achievements}>{t('footer.fund.achievements')}</Link>*/}
        {/*  <Link href={FOOTER.fund.reports}>{t('footer.fund.reports')}</Link>*/}
        {/*  <Link href={FOOTER.fund.donate}><span*/}
        {/*    style={{ whiteSpace: 'nowrap' }}>{t('footer.fund.donate')} <HeartIcon /></span></Link>*/}
        {/*</Box>*/}
        <Box className={'Footer__box Footer__fund'}>
          <Typography className={'Footer__header'} variant="h4" color="inherit">{t('footer.forum')}</Typography>
          {links.map(i => <RouterLink key={i.to} className={'FooterLink'} to={i.to}>{t(i.title)}</RouterLink>)}
          {showLang && (
            <Typography tabIndex={0} className={classnames('FooterLink', 'Footer__lang')} onClick={switchLanguage}>
              {languageToSwitch.title}
            </Typography>
          )}
        </Box>
        <ResourcesBlock className={'Footer__resources'} />
        {!isRu && (
          <div>
            <Box className={'Footer__box Footer__contacts'}>
              <Typography className={'Footer__header'} variant="h4" color="inherit">
                {t('footer.contacts.title')}
              </Typography>
              <Typography>{t('footer.contacts.address')}</Typography>
              <Link href={FOOTER.contacts.mail}>info@nakedheart.ru</Link>
            </Box>
            <SocialNetworks className={'Footer__socNetworks'} />
          </div>
        )}
      </Box>

      {isRu && (
        <Box className={'Footer__row'}>
          <SocialNetworks className={'Footer__socNetworks'} />
          <Box className={'Footer__box Footer__contacts'}>
            <Typography className={'Footer__header'} variant="h4" color="inherit">
              {t('footer.contacts.title')}
            </Typography>
            <Typography>{t('footer.contacts.address')}</Typography>
            <Link href={FOOTER.contacts.mail}>info@nakedheart.ru</Link>
          </Box>
        </Box>
      )}

      <Box className={'Footer__row'}>
        <Typography>{t('footer.copyright')}</Typography>
        <Link href={FOOTER.terms}>{t('footer.privacy_policy')}</Link>
      </Box>
      <SubscribeModal open={isSubscribeFormOpen} onClose={toggleSubscribeForm} />
    </footer>
  );
}

function Link({ className, href, children }: Elem & { href?: string }) {
  return (
    <Typography
      className={classnames('FooterLink', className)} component={'a'} href={href} target="_blank" rel="noreferrer"
    >
      {children}
    </Typography>
  );
}

export function SocialIcon({ name }: { name: 'fb' | 'ig' | 'whatsapp' | 'vk' | 'ok' | 'twitter' | 'vb' | string }) {
  const icons = {
    fb: <FBIcon />,
    ig: <IGIcon />,
    whatsapp: <WhatsappIcon />,
    vk: <VKIcon />,
    ok: <OKIcon />,
    youtube: <YouTubeIcon />,
    twitter: <TwitterIcon />,
    vb: <VbIcon />,
  } as any;
  return icons[name];
}

function useFooterResources() {
  const { i18n: { language } } = useTranslation();
  const { footer } = useAppConfig();
  // @ts-ignore
  const resources: FooterResource[] = footer[`resources_${language}`] || footer.resources_ru;
  return resources;
}

export function ResourcesBlock({ className }: ClassName) {
  const { t } = useTranslation();
  const resources = useFooterResources();
  return (
    <Box className={classnames('Footer__box', className)}>
      <Typography className={'Footer__header'} variant="h4" color="inherit">
        {t('footer.our_resources.title')}
      </Typography>
      {resources.map(i => (<Link key={i.id} href={i.link}>{i.title}</Link>))}
      {/*<Link href={FOOTER.resources.foundation}>Фонд «Обнаженные сердца»</Link>*/}
      {/*/!*<Link href={FOOTER.resources.foundationEn}>*!/*/}
      {/*/!*  {t('footer.our_resources.naked_heart_foundation')} <br /><span className={'grey'}>(english version)</span>*!/*/}
      {/*/!*</Link>*!/*/}
      {/*<Link href={FOOTER.resources.online}>{t('footer.our_resources.naked_heart_online')}</Link>*/}
    </Box>
  );
}

export function SocialNetworks({ className }: ClassName) {
  const { t } = useTranslation();
  return (
    <Box className={classnames('Footer__box', className)}>
      <Typography className={'Footer__header'} variant="h4" color="inherit">
        {t('footer.social_networks.title')}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {Object.entries(FOOTER.socialNetworks).map(([name, link]) => (
          <IconButton key={name} className={'Footer__linkBtn'} href={link} aria-label={name} target="_blank"
                      referrerPolicy="no-referrer" rel="noreferrer">
            <SocialIcon name={name} />
          </IconButton>
        ))}
      </Box>
    </Box>
  );
}

function SubscribeModal({ open, onClose }: { open: boolean, onClose?: () => void }) {
  const inited = useRef(false);
  const containerId = 'unisender-subscribe-form';
  useEffect(() => {
    if (!open || inited.current) return;
    const timeout = setTimeout(() => {
      loadSubscribeIframe(containerId);
      inited.current = true;
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [open]);
  // TODO: make dynamic link??
  return (
    <Dialog className={'SubscribeModal'} open={open} onClose={onClose} keepMounted>
      <Box
        className={'container'}
        display={open ? 'block' : 'none'}
        id={containerId}
        data-url="https://cp.unisender.com/ru/v5/subscribe-form/view/66xofnwyzzpf6ehdzbokwkj7s8nbs5s7j731ezze"
      />
    </Dialog>
  );
}

function loadSubscribeIframe(containerId: string) {
  const container = document.getElementById(containerId);
  if (!container) return;
  const iframe = document.createElement('iframe');
  const url = container.getAttribute('data-url')!;
  iframe.src = url;
  iframe.frameBorder = '0';
  iframe.scrolling = 'no';
  iframe.style.width = '100%';
  iframe.style.visibility = 'hidden';
  container.appendChild(iframe);
  // @ts-ignore
  const listener = function (event) {

    if (!event) return;
    if (!event.data) return;
    if (typeof event.data !== 'object') return;
    if (event.data.type !== "formDimensionPopulate") return;
    if (event.data.originUrl !== url) return;

    iframe.height = event.data.height;

    iframe.style.visibility = 'visible';

    window.removeEventListener('message', listener, false);
  };

  window.addEventListener('message', listener, false);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function loadSubscribeFormScript() {
  const loaderScriptLink = 'cp.unisender.com/v5/template-editor-new/js/app/preview/form/form-widget-loader.js';
  let script: HTMLScriptElement | null = null;
  let container: HTMLElement | null = null;
  (function (d, w, c) {
    // @ts-ignore
    // (w[c] = w[c] || []).push({formId:formId,host:"formdesigner.pro",formHeight:100, el: formSelectorId ,center: 1, scroll: 0});
    const s = d.createElement("script"), g = "getElementsByTagName";
    s.type = "text/javascript";
    s.charset = "UTF-8";
    s.async = true;
    s.defer = false;
    s.src = `${d.location.protocol === "https:" ? "https:" : "http:"}//${loaderScriptLink}`;
    const h = d[g]("head")[0] || d[g]("body")[0];
    h.appendChild(s);
    container = h;
    script = s;
  })(document, window, "subscribe_forms");
  return () => {
    if (container == null || script == null) return;
    container.removeChild(script);
  };
}
