import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '../../app/store';
import { ForumData } from '../../services/models';
import apiProvider from '../../services/apiProvider';

const initialState: { main: ForumData | undefined } = {
  main: undefined,
};

export const slice = createSlice({
  name: 'forumData',
  initialState,
  reducers: {
    setForumData: (state, action: PayloadAction<ForumData>) => {
      state.main = action.payload;
    },
    resetForumData: (state) => {
      state.main = undefined;
    },
  },
});

export const { setForumData, resetForumData } = slice.actions;


export const fetchForumData = (force = false): AppThunk => async (dispatch, getState) => {
  if (!(force || forumSelector(getState()) == null)) return;
  await apiProvider.fetchForumData()
    .then((data) => dispatch(setForumData(data)))
    .catch((ex) => {
      // dispatch(setResult(null));
    });
};


export const rootSelector = (state: RootState) => state.forumData;
export const forumSelector = (state: RootState) => rootSelector(state).main;
export const aboutSelector = (state: RootState) => forumSelector(state)?.about;
export const programSelector = (state: RootState) => forumSelector(state)?.program;
export const speakersSelector = (state: RootState) => forumSelector(state)?.speakers ?? [];
export const partnersSelector = (state: RootState) => forumSelector(state)?.partners ?? [];
export const galleryImagesSelector = (state: RootState) => forumSelector(state)?.gallery_images ?? [];

const reducer = { forumData: slice.reducer };
export default reducer;
