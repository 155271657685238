import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

interface AppState {
  mailing: { isFormOpen: boolean },
}

const initialState: AppState = {
  mailing: { isFormOpen: false },
};

export const slice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    toggleMailingForm: (state, action?: PayloadAction<boolean | any> | undefined) => {
      const { mailing: { isFormOpen } } = state;
      const value = typeof action?.payload === 'boolean' ? action.payload : !isFormOpen;
      state.mailing.isFormOpen = value;
    },
  },
});

const { toggleMailingForm: toggleMailingFormAction } = slice.actions;

export function toggleMailingForm(value?: boolean | any) {
  return toggleMailingFormAction(value);
}

export const rootSelector = (state: RootState) => state.appState;
export const isMailingFormOpenSelector = (state: RootState) => rootSelector(state).mailing.isFormOpen;

const reducer = { appState: slice.reducer };
export default reducer;
