import React from 'react';
import { ClassName } from './types';
import theme from '../theme/variables.module.scss';


export function SearchIcon({ color }: { color?: 'primary' }) {
  const c = getColor(color ?? 'white');
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.67322" cy="8.67322" r="7.67322" stroke={c} strokeWidth="2" />
      <path d="M14.1538 14.1534L20.0001 19.9997" stroke={c} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export function RightArrowIcon({ className, color = 'primary' }: ClassName & {
  color?: 'primary' | 'secondary' | 'currentColor'
}) {
  const c = getColor(color);
  return (
    <svg
      className={className} width="19" height="14" viewBox="0 0 19 14" fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 6.9646H16" stroke={c} strokeWidth="2" strokeLinecap="round" />
      <path d="M10.6069 1L16.6426 7.03564L10.6069 13.0713" stroke={c} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

function getColor(color: 'primary' | 'secondary' | string): string {
  if (color === 'primary') return theme.primaryColor;
  if (color === 'secondary') return theme.secondaryColor;
  return color;
}

export function MenuIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="3" width="20" height="2" rx="1" fill="white" />
      <rect x="0" y="9" width="20" height="2" rx="1" fill="white" />
      <rect x="0" y="15" width="20" height="2" rx="1" fill="white" />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 2L2 18M2 2L18 18L2 2Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function PlayIcon({ className }: ClassName) {
  return (
    <svg
      className={className} width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 14.4635V1.53649C0 0.705807 0.954138 0.237288 1.61145 0.74521L9.97598 7.20871C10.4941 7.60904 10.4941 8.39096 9.97598 8.79129L1.61145 15.2548C0.954137 15.7627 0 15.2942 0 14.4635Z"
        fill="white"
      />
    </svg>
  );
}

export function HeartIcon({ className }: ClassName) {
  return (
    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd" clipRule="evenodd"
        d="M1.798 9.87701C1.23086 9.34742 0.778609 8.70692 0.469313 7.99526C0.160016 7.2836 0.000274473 6.51597 0 5.74001C0 2.56701 2.627 1.20487e-05 5.871 1.20487e-05C6.91047 -0.00516551 7.9328 0.264843 8.83416 0.782614C9.73552 1.30039 10.4838 2.04748 11.003 2.94801C11.5232 2.0506 12.2712 1.30648 13.1713 0.79093C14.0714 0.275379 15.0917 0.00668088 16.129 0.0120121C19.373 1.21323e-05 22 2.57901 22 5.75201C21.9993 6.56468 21.824 7.3677 21.4858 8.10667C21.1476 8.84563 20.6546 9.50327 20.04 10.035L11.006 18.886L1.798 9.87601V9.87701Z"
        fill="white"
      />
    </svg>
  );
}

export function StepperIcon({ className, active = false }: ClassName & { active?: boolean }) {
  const color = active ? theme.secondaryColor : '#D9DEE9';
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="white" stroke={color} strokeWidth="4" />
    </svg>
  );
}

export function LocationIcon({ className }: ClassName) {
  return (
    <svg className={className} width="24" height="29" viewBox="0 0 24 29" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd" clipRule="evenodd"
        d="M11.6921 0C18.1365 0 23.3841 5.24762 23.3841 11.6921C23.3841 18.1365 15.8349 22.0032 11.6921 29C7.54921 22.0032 0 18.1365 0 11.6921C0 5.24762 5.24762 0 11.6921 0ZM11.6921 6.72063C14.546 6.72063 16.7556 9.02222 16.7556 11.7841C16.7556 14.6381 14.546 16.9397 11.6921 16.9397C8.8381 16.9397 6.53651 14.6381 6.53651 11.7841C6.53651 9.02222 8.8381 6.72063 11.6921 6.72063Z"
        fill="#002169"
      />
    </svg>
  );
}

export function DownloadIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5V13" stroke="#BA0C2F" strokeWidth="2" strokeLinecap="round" />
      <path d="M8 12L12 15L16 12" stroke="#BA0C2F" strokeWidth="2" strokeLinecap="round" />
      <path d="M4 17V19H20V17" stroke="#BA0C2F" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export function VideoBorder({ className }: ClassName) {
  return (
    <svg className={className} width="407" height="615" viewBox="0 0 407 615" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M191.114 63.3948C253.1 24.4937 326.424 2 405 2M59.662 613C23.0587 552.298 2 481.16 2 405.103"
            stroke="#002169" strokeWidth="4" strokeLinecap="round" />
    </svg>
  );
}

export function Video1Icon({ className }: ClassName) {
  return (
    <svg className={className} width="57" height="87" viewBox="0 0 57 87" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.5655 85C42.68 63.321 62.2688 24.7677 52.233 10.4806C50.1542 7.52115 46.4319 6.11809 42.8154 6.09026C24.8393 5.95189 20.3387 33.8761 25.2246 31.8053C30.5655 29.5416 27.628 -1.7716 12.9407 2.3783C4.79105 4.68097 0.923774 13.6964 2.25899 25.0144C3.32716 34.0688 13.0297 50.6686 18.5486 57.8367"
        stroke="#002169" strokeWidth="4" strokeLinecap="round" />
    </svg>
  );
}

export function Video2Icon({ className }: ClassName) {
  return (
    <svg className={className} width="123" height="141" viewBox="0 0 123 141" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M102.771 26.452C102.214 49.3515 97.7067 93.0299 84.134 84.5481C67.168 73.9459 62.8186 40.0184 74.5002 42.8536C86.1819 45.6887 88.4813 105.699 68.3077 102.493C48.1341 99.2878 20.9477 48.9673 37.0906 52.1148C53.2335 55.2623 80.9327 130.276 57.179 130.182C38.176 130.107 28.8838 118.114 26.6131 112.127"
        stroke="#002169" strokeWidth="4" strokeLinecap="round" />
    </svg>
  );
}

export function FBIcon() {
  return (
    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 6.66667H0V10H2.5V20H6.66667V10H9.70167L10 6.66667H6.66667V5.5C6.66667 4.38333 6.73083 4 7.5 4H8.5H10V0H6.82667C3.83 0 2.5 1.31917 2.5 3.84583V6.66667Z"
        fill="#002169"
      />
    </svg>
  );
}
export function WhatsappIcon() {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.03747 18.8665H9.03377C7.5374 18.866 6.06707 18.4905 4.76116 17.7782L0.0214844 19.0215L1.2899 14.3885C0.50748 13.0325 0.0957738 11.4945 0.0964448 9.91868C0.0984061 4.98904 4.10927 0.978394 9.03738 0.978394C11.4292 0.979426 13.674 1.91054 15.3621 3.60055C17.0501 5.29047 17.9792 7.53679 17.9783 9.92573C17.9763 14.8542 13.967 18.8645 9.03747 18.8665ZM13.5419 12.0516C13.486 11.9584 13.3371 11.9025 13.1137 11.7906C12.8903 11.6788 11.792 11.1384 11.5872 11.0638C11.3824 10.9893 11.2334 10.952 11.0845 11.1756C10.9356 11.3992 10.5075 11.9025 10.3772 12.0516C10.2468 12.2006 10.1165 12.2194 9.89321 12.1075C9.6698 11.9957 8.94996 11.7598 8.09664 10.9987C7.43251 10.4063 6.98416 9.67473 6.85384 9.45107C6.72353 9.22741 6.83995 9.10655 6.95179 8.99515C7.0523 8.89502 7.17521 8.73416 7.2869 8.60374C7.39859 8.47333 7.43582 8.38008 7.51029 8.23109C7.58475 8.08193 7.54753 7.95152 7.49167 7.83969C7.43582 7.72786 6.98904 6.62823 6.80288 6.18091C6.62153 5.74529 6.43736 5.8043 6.30024 5.79742C6.17008 5.79088 6.02098 5.7895 5.87207 5.7895C5.72315 5.7895 5.4811 5.84542 5.27633 6.06908C5.07157 6.29274 4.49445 6.83322 4.49445 7.93276C4.49445 9.0324 5.29494 10.0947 5.40665 10.2438C5.51834 10.3929 6.98194 12.6494 9.223 13.6171C9.756 13.8472 10.1721 13.9847 10.4966 14.0876C11.0317 14.2577 11.5188 14.2337 11.9037 14.1762C12.3329 14.1121 13.2254 13.6358 13.4116 13.114C13.5977 12.592 13.5977 12.1447 13.5419 12.0516Z" fill="#FDFDFD"/>
    </svg>
  );
}

export function IGIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99499 7.18018C8.44437 7.18018 7.18018 8.44437 7.18018 9.99499C7.18018 11.5456 8.44437 12.8098 9.99499 12.8098C11.5456 12.8098 12.8098 11.5456 12.8098 9.99499C12.8098 8.44437 11.5456 7.18018 9.99499 7.18018Z"
        fill="#002169"
      />
      <path
        d="M16.5333 0H3.45679C1.55062 0 0 1.55062 0 3.45679V16.5432C0 18.4494 1.55062 20 3.45679 20H16.5432C18.4494 20 20 18.4494 20 16.5432V3.45679C19.9901 1.55062 18.4395 0 16.5333 0ZM9.99506 15.7728C6.81482 15.7728 4.21728 13.1852 4.21728 9.99506C4.21728 6.80494 6.80494 4.21728 9.99506 4.21728C13.1852 4.21728 15.7728 6.80494 15.7728 9.99506C15.7728 13.1852 13.1753 15.7728 9.99506 15.7728ZM17.8864 3.5358C17.8864 4.32593 17.2444 4.9679 16.4543 4.9679C15.6642 4.9679 15.0222 4.32593 15.0222 3.5358C15.0222 2.74568 15.6642 2.1037 16.4543 2.1037C17.2444 2.1037 17.8864 2.74568 17.8864 3.5358Z"
        fill="#002169"
      />
    </svg>
  );
}

export function VKIcon() {
  return (
    <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.162 13.994C13.771 13.994 14.02 13.588 14.013 13.079C13.982 11.162 14.727 10.13 16.072 11.475C17.56 12.963 17.868 13.994 19.675 13.994H22.875C23.683 13.994 24.001 13.734 24.001 13.326C24.001 12.463 22.58 10.94 21.376 9.822C19.69 8.257 19.611 8.22 21.063 6.336C22.864 3.997 25.22 1 23.136 1H19.155C18.383 1 18.327 1.435 18.052 2.083C17.057 4.43 15.166 7.47 14.448 7.005C13.697 6.52 14.041 4.599 14.098 1.744C14.113 0.99 14.109 0.473 12.957 0.205C12.328 0.0599999 11.716 0 11.148 0C8.875 0 7.307 0.953 8.198 1.119C9.769 1.412 9.618 4.811 9.252 6.279C8.614 8.835 6.216 4.255 5.217 1.974C4.976 1.426 4.902 1 4.042 1H0.787C0.295 1 0 1.16 0 1.516C0 2.118 2.96 8.236 5.786 11.286C8.542 14.261 11.266 13.994 13.162 13.994Z"
        fill="#002169"
      />
    </svg>
  );
}

export function OKIcon() {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.7495 3.15051C6.9192 3.15051 7.87058 4.10189 7.87058 5.2716C7.87058 6.44025 6.9192 7.39162 5.7495 7.39162C4.58084 7.39162 3.62841 6.44025 3.62841 5.2716C3.62841 4.10189 4.58084 3.15051 5.7495 3.15051ZM5.7495 10.5432C8.65742 10.5432 11.0221 8.17952 11.0221 5.27265C11.0221 2.36473 8.65847 3.52698e-07 5.7495 3.52698e-07C2.84157 -0.00105439 0.476846 2.36368 0.476846 5.2716C0.476846 8.17846 2.84157 10.5432 5.7495 10.5432ZM7.82206 14.5734C8.8652 14.336 9.87142 13.9226 10.7975 13.3414C11.4989 12.8995 11.7098 11.9724 11.2679 11.272C10.827 10.5696 9.8999 10.3576 9.1985 10.7995C7.09956 12.12 4.39732 12.119 2.30049 10.7995C1.59804 10.3576 0.671973 10.5696 0.231092 11.272C-0.210845 11.9734 0.000102977 12.9005 0.701506 13.3414C1.62757 13.9226 2.63379 14.336 3.67693 14.5734L0.812253 17.438C0.226872 18.0245 0.226872 18.9748 0.813308 19.5602C1.10653 19.8534 1.49045 20 1.87438 20C2.2583 20 2.64328 19.8534 2.9365 19.5602L5.75055 16.7451L8.56671 19.5602C9.15209 20.1466 10.1024 20.1466 10.6888 19.5602C11.2753 18.9737 11.2753 18.0234 10.6888 17.438L7.82206 14.5734Z"
        fill="#002169"
      />
    </svg>
  );
}

export function YouTubeIcon() {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.615 0.184125C16.011 -0.0618748 7.984 -0.0608748 4.385 0.184125C0.488 0.450125 0.029 2.80412 0 9.00012C0.029 15.1851 0.484 17.5491 4.385 17.8161C7.985 18.0611 16.011 18.0621 19.615 17.8161C23.512 17.5501 23.971 15.1961 24 9.00012C23.971 2.81512 23.516 0.451125 19.615 0.184125ZM9 13.0001V5.00013L17 8.99312L9 13.0001Z"
        fill="#002169"
      />
    </svg>
  );
}

export function TwitterIcon() {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.375 2.13095C21.5656 2.49272 20.6957 2.73636 19.7827 2.84619C20.7149 2.28415 21.4308 1.39356 21.7673 0.33224C20.8955 0.852748 19.9293 1.23113 18.9008 1.43509C18.0786 0.551887 16.9043 0 15.6063 0C12.6922 0 10.5509 2.73728 11.2091 5.57886C7.459 5.38966 4.13333 3.5808 1.90675 0.831522C0.72425 2.87387 1.2935 5.54563 3.30283 6.89858C2.564 6.87459 1.86733 6.67063 1.25958 6.33009C1.21008 8.43519 2.70883 10.4046 4.8795 10.843C4.24425 11.0165 3.5485 11.0571 2.84083 10.9205C3.41467 12.7257 5.08117 14.039 7.0575 14.0759C5.16 15.5737 2.76933 16.2428 0.375 15.9586C2.37242 17.2478 4.74567 18 7.294 18C15.6742 18 20.4087 10.8744 20.1227 4.48339C21.0046 3.84198 21.77 3.04184 22.375 2.13095Z"
        fill="#002169"
      />
    </svg>
  );
}

export function VbIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd" clipRule="evenodd"
        d="M11.9102 15.5013C11.9102 15.5013 12.6089 15.5627 12.9849 15.096L13.7209 14.1707C14.0729 13.712 14.9315 13.4187 15.7689 13.8853C16.8782 14.512 18.3129 15.496 19.2942 16.4133C19.8355 16.8693 19.9609 17.5413 19.5929 18.2507L19.5875 18.2667C19.2089 18.936 18.7022 19.5627 18.0595 20.1493L18.0462 20.16C17.3235 20.7627 16.4942 21.112 15.6062 20.824L15.5902 20.8C13.9955 20.3493 10.1715 18.3973 7.7342 16.4533C3.74487 13.3067 0.910201 8.11733 0.126201 5.336L0.102203 5.32C-0.185797 4.42933 0.166201 3.59733 0.766201 2.88L0.776867 2.86667C1.36087 2.224 1.9902 1.71733 2.65953 1.336L2.67287 1.33333C3.3822 0.965334 4.0542 1.09067 4.51287 1.632C5.11553 2.25334 6.2462 3.74933 7.0382 5.15733C7.50487 5.99467 7.2142 6.85067 6.75553 7.20534L5.8302 7.93867C5.36353 8.31733 5.42487 9.016 5.42487 9.016C5.42487 9.016 6.79287 14.1947 11.9102 15.5013ZM19.9609 11.208C20.2355 11.2053 20.4569 10.9813 20.4569 10.7067C20.4302 7.42933 19.4275 4.8 17.4755 2.89333C15.5262 0.994667 13.0702 0.0213333 10.1822 0H10.1769C9.90487 0 9.68087 0.221334 9.6782 0.493334C9.6782 0.768 9.89954 0.994667 10.1742 0.994667C12.7982 1.01333 15.0195 1.89333 16.7795 3.608C18.5342 5.32 19.4382 7.712 19.4595 10.7147C19.4622 10.9867 19.6835 11.208 19.9582 11.208H19.9609ZM17.3422 10.184H17.3315C17.0542 10.1787 16.8382 9.952 16.8435 9.67467C16.8835 7.97333 16.3955 6.59467 15.3582 5.464C14.3235 4.33334 12.9049 3.71467 11.0195 3.576C10.7449 3.55734 10.5369 3.31733 10.5582 3.04533C10.5795 2.77067 10.8169 2.56533 11.0915 2.584C13.2142 2.73867 14.8969 3.48267 16.0915 4.78934C17.2942 6.104 17.8835 7.75467 17.8382 9.69867C17.8329 9.97067 17.6115 10.184 17.3422 10.184ZM14.7875 9.328C14.5235 9.328 14.3022 9.12267 14.2889 8.856C14.2062 7.16533 13.4089 6.33867 11.7849 6.25067C11.5102 6.23467 11.2995 6 11.3129 5.72533C11.3289 5.45067 11.5635 5.24 11.8382 5.256C13.9822 5.37067 15.1742 6.6 15.2835 8.80533C15.2969 9.08 15.0862 9.31467 14.8115 9.328H14.7875Z"
        fill="#002169"
      />
    </svg>
  );
}
