import apiProvider from './apiProvider';
import {
  archiveForums,
  forumData,
  galleryList,
  newsList,
  program,
  searchSuggestions,
  signUpData,
  speakers,
  termsPage,
  yearTags
} from './mockModels';
import { GalleryImage, News, Pagination, Speaker } from './models';

const enabled = false;
const patchMain = true;
const patchSignup = true;
const patchProgram = true;
const patchSpeakers = true;
const patchNews = true;
const patchGallery = true;
const patchArchives = true;
const patchSearch = true;
const patchTerms = true;

if (enabled) {
  if (patchMain) {
    apiProvider.fetchForumData = () => {
      return withLog(delayedValue(400, forumData), 'fetchForumData');
    };
  }
  if (patchSignup) {
    apiProvider.fetchSignUpData = () => {
      return withLog(delayedValue(400, signUpData), 'fetchSignUpData');
    };
  }
  if (patchProgram) {
    apiProvider.fetchProgram = () => {
      return withLog(delayedValue(400, program), 'fetchProgram');
    };
  }
  if (patchSpeakers) {
    apiProvider.fetchSpeakers = ({ next }: any = {}) => {
      const data: Pagination<Speaker> = { count: 10, next: `${next || 1}1`, results: speakers };
      if (next?.length && next.length > 3) data.next = null;
      return withLog(delayedValue(400, data), 'fetchSpeakers');
    };
    apiProvider.fetchSpeaker = (id) => {
      const items = Object.fromEntries(speakers.map(i => [i.id, i]));
      return withLog(delayedValue(400, items[id] ?? { ...items[4], id }), 'fetchSpeaker');
    };
    apiProvider.fetchSpeakerFilters = () => {
      return withLog(delayedValue(400, { years: yearTags }), 'fetchSpeakerFilters');
    };
  }
  if (patchNews) {
    apiProvider.fetchNewsList = ({ next }: any = {}) => {
      const data: Pagination<News> = { count: 10, next: `${next || 1}1`, results: newsList };
      if (next?.length && next.length > 3) data.next = null;
      return withLog(delayedValue(400, data), 'fetchNewsList');
    };
    apiProvider.fetchNews = (id) => {
      const items = Object.fromEntries(newsList.map(i => [i.id, i]));
      return withLog(delayedValue(400, items[id] ?? { ...items[1], id }), 'fetchNews');
    };
    apiProvider.fetchNewsFilters = () => {
      return withLog(delayedValue(400, { years: yearTags }), 'fetchNewsFilters');
    };
  }
  if (patchGallery) {
    apiProvider.fetchGalleryList = ({ next }: any = {}) => {
      const data: Pagination<GalleryImage> = { count: 10, next: `${next || 1}1`, results: galleryList };
      if (next?.length && next.length > 3) data.next = null;
      return withLog(delayedValue(400, data), 'fetchGalleryList');
    };
    apiProvider.fetchGalleryImage = (id) => {
      const items = Object.fromEntries(galleryList.map(i => [i.id, i]));
      return withLog(delayedValue(400, items[id] ?? { ...items[1], id }), 'fetchGalleryImage');
    };
    apiProvider.fetchGalleryFilters = () => {
      return withLog(delayedValue(400, { years: yearTags }), 'fetchGalleryFilters');
    };
  }
  if (patchArchives) {
    apiProvider.fetchArchives = () => {
      return withLog(delayedValue(400, archiveForums), 'fetchArchives');
    };
  }
  if (patchTerms) {
    apiProvider.fetchTerms = () => {
      return withLog(delayedValue(400, termsPage), 'fetchTerms');
    };
    apiProvider.fetchSignUpConditions = () => {
      return withLog(delayedValue(400, termsPage), 'fetchSignUpConditions');
    };
  }
}
if (patchSearch) {
  apiProvider.fetchSearchSuggestions = () => {
    return withLog(delayedValue(400, searchSuggestions), 'fetchArchives');
  };
}

function sleeper<T = any>(ms: number) {
  return (x: T | Promise<T>) => new Promise<T>(resolve => setTimeout(() => resolve(x), ms));
}

function delayedValue<T>(ms: number, value: T | Promise<T>) {
  return sleeper<T>(ms)(value);
}

function withLog<T>(value: Promise<T>, name: string) {
  const prefix = `_____MOCK: ${name}`;
  console.log(prefix);
  return value.then((value) => {
    console.log(`${prefix}:`, value);
    return value;
  });
}
