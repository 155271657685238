import React, { HTMLAttributeAnchorTarget, HTMLAttributeReferrerPolicy, MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames';
import * as H from 'history';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

import css from './elements.module.scss';
import { ClassName, Elem } from '../types';
import { LocationIcon } from '../Icons';
import Button, { ContainButton } from '../buttons/Buttons';
import { usePageMargin } from '../layout/responsive';
import { Swiper, SwiperSlide } from './Swiper';
import { SwiperOptions } from 'swiper';
import { Tag } from '../../services/models';

export function LocationRow({ className, title, subtitle }: ClassName & { title: ReactNode, subtitle: ReactNode }) {
  return (
    <div className={classnames(css.LocationRow, className)}>
      <LocationIcon className={css.LocationRow__icon} />
      <Box display="flex" flexDirection="column">
        {title}
        <span className={css.LocationRow__subtitle}>{subtitle}</span>
      </Box>
    </div>
  );
}


export function TagChip({ className, tag }: Elem & { tag: Tag }) {
  const hasLink = !!tag.link;
  return (
    <ContainButton
      className={classnames(css.TagChip, className)} disableRipple={!hasLink}
      href={tag.link} target="_blank" referrerPolicy="no-referrer"
    >
      {tag.name}
    </ContainButton>
  );
}


export function SectionChip({ className, children }: Elem) {
  return (
    <Typography className={classnames(css.SectionChip, className)} variant="inherit" component="div">
      {children}
    </Typography>
  );
}

export type LinkProp<S = H.LocationState> =
  H.LocationDescriptor<S>
  | ((location: H.Location<S>) => H.LocationDescriptor<S>);

export function Link<S = H.LocationState>({ className, color, ...props }: LinkProps<S> & { color?: 'primary' }) {
  const cn = classnames(css.Link, className, { [css.primary]: color === 'primary' });
  return (<RouterLink className={cn} {...props} />);
}

export function MaybeLink<S = H.LocationState>(
  { className, link, target, referrerPolicy = 'no-referrer', innerRef, ...others }: Elem & {
    link?: LinkProp<S>,
    target?: HTMLAttributeAnchorTarget, referrerPolicy?: HTMLAttributeReferrerPolicy,
    innerRef?: React.Ref<HTMLElement> | undefined,
    onClick?: MouseEventHandler<HTMLElement>,
  }
) {
  const Wrapper = link ? Link : 'div';
  const props = link
    ? { to: link, target, referrerPolicy, innerRef: innerRef as any }
    : { to: { pathname: undefined }, ref: innerRef as any };
  return (<Wrapper className={className} {...others} {...props} />);
}

export function FiltersRow(
  {
    className, title, chips, swipeable = false, useMargin = true, withNegativeMargin = true,
    swiperOption = { freeMode: true },
  }: {
    title?: ReactNode, chips: ReactNode[], swipeable?: boolean,
    useMargin?: boolean | number, withNegativeMargin?: boolean,
    swiperOption?: SwiperOptions,
  } & ClassName
) {
  const pageMargin = usePageMargin();
  const hasTitle = !!title;
  const margin = typeof useMargin === 'boolean' ? pageMargin : useMargin;
  return (
    <div
      className={classnames(css.FiltersRow, className, { [css.swipeable]: swipeable })}
      style={{
        marginLeft: (useMargin && withNegativeMargin) ? -margin : undefined,
        marginRight: (useMargin && withNegativeMargin) ? -margin : undefined,
        paddingLeft: (useMargin && hasTitle) ? margin : undefined,
        paddingRight: (useMargin && !swipeable) ? margin : undefined,
      }}
    >
      {hasTitle && (
        <Typography className={css.FiltersRow__title} variant="subtitle2" component="div">{title}</Typography>
      )}
      {!swipeable && (
        <Box flex={1} display="flex" alignItems="baseline" flexWrap="wrap">{chips}</Box>
      )}
      {swipeable && (
        <Swiper
          className={css.Swiper}
          watchSlidesProgress={true} watchSlidesVisibility={true}
          slidesPerView="auto"
          touchStartPreventDefault={false}
          slidesOffsetBefore={(useMargin && !hasTitle) ? margin : 0}
          slidesOffsetAfter={useMargin ? margin : 0}
          {...swiperOption}
        >
          {chips.map((i, ind) => (
            <SwiperSlide key={ind} className={css.SwiperSlide}>{i}</SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}


export function FilterChip({ className, children, selected = false, color = 'secondary', onClick }: Elem & {
  selected?: boolean, color?: 'primary' | 'secondary', onClick?: () => void
}) {
  const c = classnames(css.FilterChip, className, { [css.selected]: selected });
  const btnColor = selected ? 'inherit' : color;
  return (<Button className={c} variant="outlined" color={btnColor} onClick={onClick}>{children}</Button>);
}

export function TileItem({ className, leading, title, subtitle, size, link, contentLink, target }: ClassName & {
  leading?: ReactNode, title?: ReactNode, subtitle?: ReactNode, size?: 'small',
  link?: string, contentLink?: string, target?: HTMLAttributeAnchorTarget
}) {
  return (
    <MaybeLink
      className={classnames(css.TileItem, className, { [css.small]: size === 'small' })}
      link={link}
      target={target}
    >
      {leading}
      <MaybeLink className={css.TileItem__container} link={contentLink} target={target}>
        {title && <div className={'title'}>{title}</div>}
        {subtitle && <div className={classnames(css.TileItem__subtitle, 'subtitle')}>{subtitle}</div>}
      </MaybeLink>
    </MaybeLink>
  );
}
