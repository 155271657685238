import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Drawer, IconButton, TextField, Typography } from '@material-ui/core';

import css from './search.module.scss';
import { RectButton } from '../appBar/AppBar';
import { CloseIcon, SearchIcon } from '../../Icons';
import { useIsMobile, useIsTablet } from '../responsive';
import { FilterChip, FiltersRow, Link, TileItem } from '../../elements/Elements';
import { News, Program, ProgramSection, SearchResult, SearchSuggestion, Speaker } from '../../../services/models';
import { useDebounceStateCallback } from '../../../hooks/debounce';
import { ClassName, Elem } from '../../types';
import { Image, Loader } from '../pageLayout/PageLayout';
import { ContainButton, OutlineButton } from '../../buttons/Buttons';
import { newsDetailedRoute, programDetailedRoute, programRoute, speakerDetailedRoute } from '../../../routes';
import { useLoading, useMounted } from '../../../hooks/react';
import apiProvider from '../../../services/apiProvider';
import { usePaginationState } from '../../../hooks/fetch';
import { timeRangeFormat, useMoment } from '../../../hooks/moment';
import { SpeakersRow } from '../../../features/program/components';

export default function SearchDrawer({ open, onClose }: { open: boolean, onClose?: () => void }) {
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const mounted = useMounted();
  const { loading, withLoading } = useLoading();
  const { currentPage, items, append, reset } = usePaginationState<SearchResult>();
  const [searching, setSearching] = useState('');
  const searchDebounceCallback = useCallback((value: string) => {
    if (!value) {
      reset();
      setSearching('');
      return;
    }
    withLoading(apiProvider.search({ value })).then(items => reset(items));
    setSearching(value);
  }, [reset, withLoading]);
  const [searchValue, setSearchValue] = useDebounceStateCallback({
    initialValue: '', callback: searchDebounceCallback
  });
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>();
  const hasSuggestions = Boolean(suggestions?.length);
  const hasItems = Boolean(items?.length);
  const hasNextPage = !!currentPage?.next;
  const showNoResult = searching && !loading && searchValue && !hasItems;

  const handleSuggestionClick = useCallback((value: SearchSuggestion) => {
    setSearchValue(value);
  }, [setSearchValue]);
  const handleNextPageClick = useCallback(() => {
    withLoading(apiProvider.search({ next: currentPage.next })).then(items => {
      if (!mounted.current) return;
      append(items);
    });
  }, [append, currentPage.next, mounted, withLoading]);

  const handleItemLinkClick: ItemLinkClick = useCallback((item: SearchResult) => {
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (!open && searchValue) setSearchValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  useEffect(() => {
    if (!open) return;
    withLoading(apiProvider.fetchSearchSuggestions()).then(items => {
      if (mounted.current) setSuggestions(items);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Drawer
      className={css.Drawer} classes={{ paper: css.paper }}
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <div className={classnames(css.background, { [css.no_result]: showNoResult })} />
      {isTablet && (<Box display="flex" justifyContent="flex-end">
        <IconButton className={css.closeBtn} color="secondary" onClick={onClose}><CloseIcon /></IconButton>
      </Box>)}
      <Box display="flex" justifyContent="space-between">
        <TextField
          className={css.Drawer__input}
          classes={{ root: css.inputRoot }}
          fullWidth
          variant="filled"
          placeholder={t('common.search')}
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
          InputProps={{ startAdornment: <span className={css.icon}><SearchIcon color="primary" /></span> }}
        />
        {!isTablet && <RectButton color="secondary" onClick={onClose}><CloseIcon /></RectButton>}
      </Box>
      {hasSuggestions && !isMobile && (
        <div className={css.suggestions}>
          <FiltersRow
            swipeable={isTablet}
            useMargin={isTablet ? 48 : 55}
            title={`${t('search.popular_suggestions')}:`}
            chips={suggestions?.map(i => (
              <FilterChip
                key={i} className={css.chip}
                color="primary"
                onClick={() => handleSuggestionClick(i)}
              >
                {i}
              </FilterChip>
            )) ?? []}
          />
        </div>
      )}
      {!searchValue && hasSuggestions && isMobile && (
        <div className={css.suggestions}>
          <Typography className={css.suggestions__title}>{`${t('search.popular_suggestions')}:`}</Typography>
          {suggestions?.map(i => (
            <FilterChip
              key={i} className={css.chip}
              color="primary"
              onClick={() => handleSuggestionClick(i)}
            >
              {i}
            </FilterChip>
          ))}
        </div>
      )}
      {showNoResult && (
        <div className={css.noResult}>
          <NoResultImage />
          <div className={css.noResult__content}>
            <div>
              <Typography variant="subtitle2" color="inherit">
                {t('search.by_query')}
                <strong> {searching}</strong>
              </Typography>
            </div>
            <Typography variant="subtitle2" color="inherit">{t('search.nothing_found')}</Typography>
            <Typography className={css.noResult__hint}>{t('search.nothing_found_hint')}</Typography>
          </div>
        </div>
      )}
      {hasItems && (
        <div className={css.container}>
          {items.map(i => <SearchResultCard key={`${i.type}${i.object!.id}`} item={i} onClick={handleItemLinkClick} />)}
          {hasNextPage && (<Box display="flex" justifyContent="center">
            <OutlineButton onClick={handleNextPageClick}>{t('common.next_page')}</OutlineButton>
          </Box>)}
        </div>
      )}
      {loading && <Loader color="secondary" />}
    </Drawer>
  );
}

function SearchCard({ className, children, image, link, onClick }: Elem & {
  image?: string, link?: string, onClick?: () => void
}) {
  const Wrapper = link ? Link : Box;
  return (
    <Wrapper
      className={classnames(css.SearchCard, { [css.hasImage]: image }, className)}
      to={link ?? ''} onClick={onClick}>
      <div className={css.SearchCard__container}>{children}</div>
      {image && <Image className={css.SearchCard__image} src={image} alt={'превью'} />}
    </Wrapper>
  );
}

function Text({ className, style, children, color = 'inherit' }: Elem & {
  style?: React.CSSProperties, color?: 'inherit' | 'primary' | 'secondary'
}) {
  const cn = classnames(css.Text, className);
  return (
    <Typography
      className={cn} style={style} color={color} component="div"
      dangerouslySetInnerHTML={{ __html: children?.toString() || '' }}
    />
  );
}

function Title({ className, style, children }: Elem & { style?: React.CSSProperties }) {
  return (<Text className={classnames(css.title, className)} style={style} color="primary">{children}</Text>);
}

function Subtitle({ className, style, children, color = 'inherit' }: Elem & {
  style?: React.CSSProperties, color?: 'inherit' | 'primary' | 'secondary'
}) {
  return (<Text className={classnames(css.subtitle, className)} style={style} color={color}>{children}</Text>);
}

function SmallText({ className, style, children }: Elem & { style?: React.CSSProperties }) {
  return (<Text className={classnames(css.small, className)} style={style}>{children}</Text>);
}

function Spacer({ className, children, flex = 1 }: Elem & { flex?: number }) {
  return (<Box className={className} flex={flex}>{children}</Box>);
}

function DangerHtml({ className, children }: ClassName & { children?: string }) {
  return (<div className={className} dangerouslySetInnerHTML={{ __html: children ?? '' }} />);
}

function Chip({ className, children }: Elem) {
  return (<ContainButton className={classnames(css.Chip, className)} disableRipple>{children}</ContainButton>);
}

type ItemLinkClick = (item: SearchResult) => void;

function SearchResultCard({ item, onClick }: { item: SearchResult, onClick: ItemLinkClick }) {
  const handleClick: any = useCallback((e: any) => {
    onClick(item);
  }, [item, onClick]);
  if (item.type === 'news') return (<NewsCard item={item.object as News} onClick={handleClick} />);
  if (item.type === 'speaker') return (<SpeakerCard speaker={item.object as Speaker} onClick={handleClick} />);
  if (item.type === 'program') return (<ProgramCard item={item.object as Program} onClick={handleClick} />);
  if (item.type === 'section')
    return (<ProgramSectionCard item={item.object as ProgramSection} onClick={handleClick} />);
  return null;
}

function SpeakerCard({ speaker, onClick }: { speaker: Speaker, onClick?: () => void }) {
  const { t } = useTranslation();
  return (
    <SearchCard
      className={css.SpeakerCard} image={speaker.image} link={speakerDetailedRoute(speaker.id)} onClick={onClick}>
      <Chip>{t('common.speaker')}</Chip>
      <Title>{speaker.full_name}</Title>
      <Subtitle>{speaker.organization}</Subtitle>
      <Spacer />
      {speaker.description && (<SmallText>{speaker.description}</SmallText>)}
    </SearchCard>
  );
}

function ProgramCard({ item, onClick }: { item: Program, onClick?: () => void }) {
  const { t } = useTranslation();
  return (
    <SearchCard className={css.ProgramCard} link={programRoute(item.year)} onClick={onClick}>
      <Chip>{t('common.forum_program')}</Chip>
      <Title>{item.year}</Title>
      <Subtitle color="primary">{item.theme}</Subtitle>
      <Subtitle>{item.title}</Subtitle>
      <Spacer />
      <TileItem
        className={css.location}
        size="small"
        title={<DangerHtml>{item.location}</DangerHtml>}
        subtitle={<SmallText>{item.address}</SmallText>}
      />
    </SearchCard>
  )
}

function ProgramSectionCard({ item, onClick }: { item: ProgramSection, onClick?: () => void }) {
  const { t } = useTranslation();
  const moment = useMoment();
  return (
    <SearchCard
      className={css.ProgramSectionCard}
      link={programDetailedRoute(moment(item.date).year(), item.date, item.id)} onClick={onClick}>
      <Chip>{t('common.forum_program')}</Chip>
      <Title>{timeRangeFormat(item.start_at, item.finish_at)}</Title>
      <Subtitle color="primary">{item.subject || item.name}</Subtitle>
      {Boolean(item.moderators?.length) && (
        <SmallText>
          {`${t('program_detailed.moderators')} `}
          {item.moderators?.map(i => [i.full_name, i.organization].join(', ')).join('; ')}
        </SmallText>
      )}
      <Spacer />
      <SpeakersRow className={css.speakersRow} speakers={item.speakers} size="medium" showOrganization />
    </SearchCard>
  );
}

function NewsCard({ item, onClick }: { item: News, onClick?: () => void }) {
  const { t } = useTranslation();
  const moment = useMoment();
  return (
    <SearchCard
      link={newsDetailedRoute(item.id)}
      onClick={onClick}
      image={item.head_image?.small}
    >
      <Chip>{t('common.news')}</Chip>
      <Title>{item.title}</Title>
      <SmallText>{item.lead}</SmallText>
      <Spacer />
      <SmallText>{moment(item.published_at).format('D MMMM yyyy')}</SmallText>
    </SearchCard>
  );
}

function NoResultImage() {
  return (
    <svg width="321" height="212" viewBox="0 0 321 212" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="46.5093" cy="195.015" r="13.9851" stroke="white" strokeWidth="6" />
      <circle cx="11.158" cy="172.872" r="8.15799" stroke="white" strokeWidth="6" />
      <rect x="79.0275" y="80.1708" width="141.496" height="96.7724" rx="20" stroke="white" strokeWidth="6" />
      <rect x="102.204" y="117.935" width="69.5281" height="5.90081" fill="white" />
      <rect x="102.204" y="102.593" width="69.5281" height="4.72061" fill="white" />
      <rect x="102.204" y="134.457" width="45.1323" height="4.72065" fill="white" />
      <path opacity="0.1" fillRule="evenodd" clipRule="evenodd"
            d="M235.307 0C232.512 12.2891 226.699 20.9947 217.438 24.8626C226.699 28.7304 232.512 37.436 235.307 49.7249C238.1 37.4358 243.915 28.7304 253.178 24.8626C243.915 20.9948 238.1 12.2893 235.307 0Z"
            fill="white" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M288.076 51.2788C284.075 68.527 275.756 80.7458 262.501 86.1746C275.756 91.6033 284.075 103.822 288.076 121.07C292.072 103.822 300.396 91.6032 313.652 86.1746C300.396 80.7459 292.072 68.5273 288.076 51.2788Z"
            stroke="white" strokeWidth="5" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M226.947 1.45937C224.488 12.297 219.373 19.9744 211.223 23.3855C219.373 26.7965 224.488 34.4738 226.947 45.3112C229.405 34.4736 234.522 26.7964 242.673 23.3855C234.522 19.9745 229.405 12.2971 226.947 1.45937Z"
            stroke="white" strokeWidth="5" />
      <ellipse rx="14.748" ry="14.4028" transform="matrix(-0.930748 0.365662 0.387002 0.922079 38.6168 67.6213)"
               stroke="white" strokeWidth="6" />
      <circle cx="211.099" cy="136.62" r="24.0855" transform="rotate(-45 211.099 136.62)" fill="white" />
      <path d="M227.581 153.101L246.81 172.33" stroke="white" strokeWidth="6" />
    </svg>
  );
}
