import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import globalMoment from 'moment';
import 'moment/locale/ru';

import { StringOrDate } from '../services/models';
import { isString } from '../utils/types';
import { Language } from '../localization/localization';

export const moment = globalMoment;

export const MomentContext = createContext({ moment });


type MomentType = typeof globalMoment;
const localeMoments: { ru: MomentType, en: MomentType } = {
  ru: globalMoment,
  en: cloneMomentWrapper(),
};

/// use wrapper for new moment object link.
function cloneMomentWrapper(): MomentType {
  const momentWrapper = function () {
    return globalMoment.apply(null, arguments as any);
  };
  return Object.assign(momentWrapper, globalMoment as any);
}

export function useMomentProvider(): { moment: MomentType } {
  const { i18n } = useTranslation();
  const [value, setValue] = useState({ moment: localeMoments.ru, lang: 'ru' });
  const lang = i18n.isInitialized ? i18n.language : null;
  const handleChangeLang = useCallback((lang: Language | null) => {
    setValue((value) => {
      if (!lang || lang === value.lang) return value;
      const moment = lang === 'ru' ? localeMoments.ru : localeMoments.en;
      moment.locale(lang);
      return { moment, lang };
    });
  }, []);

  useEffect(() => {
    handleChangeLang(lang as any);
  }, [handleChangeLang, lang]);
  return value;
}

export function useMoment() {
  const { moment } = useContext(MomentContext);
  return moment;
}

export function concatTime(date: StringOrDate, time: string): Date {
  const d = moment(date).startOf('day').format('yyyy-MM-DD');
  return moment(`${d} ${time}`).toDate();
}

export function timeFormat(date: StringOrDate) {
  if (isString(date)) return date.toString();
  const d = moment(date);
  if (!d.isValid()) return date.toString();
  return d.format('HH:mm');
}

export function timeRangeFormat(start: StringOrDate, end: StringOrDate): string {
  const from = timeFormat(start);
  const to = timeFormat(end);
  if (from === to) return from;
  return `${from}–${to}`;
}
